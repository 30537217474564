import React, { useEffect } from 'react'
import './footer.scss'
import footerkanvas from '../../assets/images/footer/footer-kanvas.svg'
import { ReactComponent as Facebook } from '../../assets/images/icons/footer/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/icons/footer/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/images/icons/footer/twitter.svg';
import { ReactComponent as Linkdin } from '../../assets/images/icons/footer/linkdin.svg';
import { useNavigate } from 'react-router';
import { aboutUs, contactUs, gettraininglandingRoute, gettreatmentlandingRoute, indexPattern, privacyPolicy, traininglanding } from '../../Routes';
import TreatmentPages from '../../API/treatmentPage.json';
import TrainingPages from '../../API/trainingPage.json';

const Footer = () => {
    const navigate = useNavigate()
    const today = new Date();

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };
        const routeTags = document.querySelectorAll('.routes-tag');
        routeTags.forEach(tag => {
            tag.addEventListener('click', scrollToTop);
        });
        return () => {
            routeTags.forEach(tag => {
                tag.removeEventListener('click', scrollToTop);
            });
        };
    }, []);

    return (
        <>

            <div className="footer-div">
                <div className="container-xxl">
                    <div className='d-flex flex-column justify-content-cente align-items-center'>
                        <div className="kanvas-image">
                            <img src={footerkanvas} alt="kanvaslogo" />
                        </div>
                        <div className="social-media-icons">
                            <div className=''>
                                <a className='social-media-icon' href="https://www.facebook.com/KanvasMedicalGroup" target='_blank'><Facebook /></a>
                                <a className='social-media-icon' href="https://www.instagram.com/kanvasmedicalgroup/" target='_blank'><Instagram /></a>
                                <a className='social-media-icon' href="https://twitter.com/kanvascosmetic?lang=en" target='_blank'><Twitter /></a>
                                <a className='social-media-icon' href="https://uk.linkedin.com/company/kanvasmedicalgroup" target='_blank'><Linkdin /></a>

                            </div>
                        </div>
                        {/* <div className='routes-div d-flex flex-column flex-sm-row'>
                            <div className='routes-tag' onClick={() => window.open(`https://www.kanvasmedicalgroup.com/blog/`, '_blank')}>
                                <span>News</span>
                            </div>
                            <div className='routes-tag' onClick={() => navigate(aboutUs)}>
                                <span>About us</span>
                            </div>
                            <div className='routes-tag' onClick={() => navigate(privacyPolicy)}>
                                <span>Privacy Policy</span>
                            </div>
                            <div className='routes-tag' onClick={() => navigate(contactUs)}>
                                <span>Contact us</span>
                            </div>
                        </div> */}

                        <div className='linksWrapper'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-4'>
                                    <div className='linksMainWrapper'>
                                        <h5>Useful Links</h5>
                                        <ul>
                                            {/* <li>
                                                <div className='routes-tag' onClick={() => window.open(`https://www.kanvasmedicalgroup.com/blog/`, '_blank')}>
                                                    <span>News</span>
                                                </div>
                                            </li> */}
                                            <li>
                                                <div className='routes-tag' onClick={() => navigate(aboutUs)}>
                                                    <span>About us</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='routes-tag' onClick={() => navigate(privacyPolicy)}>
                                                    <span>Privacy Policy</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='routes-tag' onClick={() => navigate(contactUs)}>
                                                    <span>Contact us</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <div className='linksMainWrapper'>
                                        <h5>Treatment Links</h5>
                                        <ul>
                                            {TreatmentPages?.Data?.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className='routes-tag' onClick={() => navigate(gettreatmentlandingRoute(item?.pageURl))}>
                                                            <span>{item?.pagename} </span>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>

                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <div className='linksMainWrapper'>
                                        <h5>Training Links</h5>
                                        <ul>
                                            {TrainingPages?.Data?.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className='routes-tag' onClick={() => navigate(gettraininglandingRoute(item?.pageURl))}>
                                                            <span>{item?.pagename} </span>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="hr-div w-100">
                            <hr />
                        </div>

                        <div className="copyrights">
                            <span>
                                © Copyright {today.getFullYear()} • All Rights Reserved.
                            </span>
                        </div>
                        <div className='poweredby'>
                            <span><a href={process.env.REACT_APP_DASHBOARD_URL} target='_blank'>Powered by Faces</a></span>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Footer