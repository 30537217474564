import { CLEAR_RATING_REVIEWS_CONST, GET_RATING_REVIEWS_CONST } from "../../constants/courses/RatingReviews";

const initialState = {
    ratingReviewsList: [],
    totalRatingeviewsCount: 0,
    loadedRatingeviewsCount: 0,
    ratingReviewsListPage: 1,
}

export const GetRatingReviewsListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_RATING_REVIEWS_CONST:
            let temp = (state?.ratingReviewsList || []).concat(
                (action.payload.data?.reviews || []).map(review => ({
                    ...review,
                    showReply: true
                }))
            );
            return {
                ...state,
                ratingReviewsList: temp,
                totalRatingeviewsCount: action.payload.data?.totalReviews,
                loadedRatingeviewsCount: temp.length,
                ratingReviewsListPage: action.payload.data?.currentPage,
            };

        case CLEAR_RATING_REVIEWS_CONST:
            return {
                ...state,
                ratingReviewsList: [],
                totalRatingeviewsCount: 0,
                loadedRatingeviewsCount: 0,
                ratingReviewsListPage: 1,
            };
        default:
            return state
    }
}  
