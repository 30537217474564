import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import toast from 'react-hot-toast';
import { GET_COURSE_LISTING_CONST } from '../../constants/courses/Listing'


//====================COURSE LISTING====================

const GET_COURSE_LISTING_QUERY = gql`
query GetCourseList($page: Int!, $limit: Int!, $isPopular: Boolean, $categoryId: Int) {
  getCourseList(page: $page, limit: $limit, isPopular: $isPopular, category_id: $categoryId) {
    success
    message
    data {
      totalCourses
      totalPages
      currentPage
      courses {
        courseId
        courseName
        courseImage
        description
        price
        isPopular
        entryRequirements {
          entryRequirementId
          text
        }
        courseSlug
        categoryName
        courseType
        courseTypeReadable
        depositAmount
        totalRatings
        averageRating
        categories {
          categoryId
          categoryName
        }
        loggedInUserHasPurchasedTheCourse
        userHasAddedCourseToCart
      }
    }
  }
}
`

export const GetCourseListingAction = () => {
  const dispatch = useDispatch()
  const [getCourseListData, courseListData] = useLazyQuery(
    GET_COURSE_LISTING_QUERY,
    //api response will be generated here!
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      context: {
        headers: {
          ['provider-identifier']: 'kanvas'
        }
      },
      onCompleted: ({ getCourseList: responseData }) => {
        dispatch({
          type: GET_COURSE_LISTING_CONST,
          payload: responseData
        })

        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          toast.error(responseData?.message)

        }
      }
    }
  )

  //this will be called from components
  const courseListQueryData = courseListData //variable

  const initCourseListData = (data) => {
    getCourseListData({
      variables: data
    })
  }

  return { initCourseListData, courseListQueryData }
}

// ==============================================================


//====================COURSE DETAILS====================

export const GET_COURSE_DETAILS_QUERY = gql`
query GetCoursePreview($courseSlug: String!, $sessionId: String) {
  getCoursePreview(courseSlug: $courseSlug, session_id: $sessionId) {
    success
    message
    data {
      courseName
      courseId
      courseImage
      entryRequirements {
        entryRequirementId
        text
      }
      description
      modules {
        moduleId
        title
        orderId
      }
      isPurchased
      coursePrice
      encrypted_id
      courseType
      courseTypeReadable
      depositAmount
      totalRatings
      averageRating
      categories {
        categoryId
        categoryName
      }
      loggedInUserHasPurchasedTheCourse
      userHasAddedCourseToCart
    }
  }
}
`

export const GetCourseDetailsAction = () => {

  const [getCourseDetailsResponseFunction, courseDetailsResponseData] = useLazyQuery(
    GET_COURSE_DETAILS_QUERY,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getCoursePreview: responseData }) => {
      }
    }
  )
  //this will be called from components
  const courseDetailsData = courseDetailsResponseData //variable

  const initCourseDetailsData = (data) => {
    getCourseDetailsResponseFunction({
      variables: { ...data, sessionId: sessionStorage.getItem("sessionString") }
    })
  }
  return { courseDetailsData, initCourseDetailsData }
}

// ==============================================================

