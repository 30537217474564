import Cookies from "js-cookie";

let baseFrontendUrl = window.location.protocol + "//" + window.location.host;

if (process.env.PUBLIC_URL) {
  baseFrontendUrl += process.env.PUBLIC_URL;
}

export const generateUrlForPath = (routerPath) => {
  return baseFrontendUrl + routerPath;
};

export const generateUrlForTrainingDashboard = (path = "") => {
  return (
    process.env.REACT_APP_LIVE_URL +
    "/auth/seamless-login?faces_login_token=" +
    Cookies.get("faces_login_token") +
    "&redirect_to=" +
    process.env.REACT_APP_SERVER_URL +
    path
  );
};
