import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_BOOKING_ID, SET_BOOKING_DETAILS } from "../../constants";

const COMPLETE_PAYMENT_BOOKING_MUTATION = gql`
mutation KanvasBookingPayment($bookingId: Int!, $paymentId: String, $paymentType: Int) {
  kanvasBookingPayment(booking_id: $bookingId, payment_id: $paymentId, payment_type: $paymentType) {
    success
    message
    data {
      clinic_name
      booking_date
      booking_time
      treatments
      readable_treatment
      capture_id
      transfer_id
      depositScheduledPayouts_id
      consent_form_link
      review_added
    }
    
  }
}
`;

export const CompletePaymentBooking = () => {
  const [completePaymentBookingRequest, completePaymentBookingResponseData] =
    useMutation(COMPLETE_PAYMENT_BOOKING_MUTATION);
  const dispatch = useDispatch();
  const completePaymentBookingMutationData = completePaymentBookingResponseData;

  const initiateCompletePaymentBooking = (
    data,
    successCallback,
    errorCallback
  ) => {
    completePaymentBookingRequest({
      variables: data,
    })
      .then(({ data: { kanvasBookingPayment: responseData } }) => {
        console.log("responseData",responseData)

        if (responseData?.success) {
            dispatch({
              type: SET_BOOKING_DETAILS,
              payload: responseData?.data?.treatmentDetails
            })
          successCallback(responseData);
        } else {
          errorCallback(responseData);
        }
      })
      .catch((error) => {
        //dispatch(setLoaderComplete("fullPageLoader"));
        errorCallback(error);
      });
  };
  return { initiateCompletePaymentBooking, completePaymentBookingMutationData };
};
