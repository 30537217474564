import "./paylater-success-page.scss";
import StepButton from "../StepButton";
import Loader from "../../loader/Loader";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CancleModal from "../CancleModal/CancleModal";
import SkeletonLoader from "../Skeleton/SkeletonLoader";
import FeedBackModal from "../FeedBackModal/FeedBackModal";
import FeedBackThanks from "../FeedBackModal/FeedBackThanks";
import FailedIcon from '../../../assets/images/icons/fail.png';
import { useLocation, useNavigate, useParams } from "react-router";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { ReactComponent as SuccessIcon } from '../../../assets/images/icons/success.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close_icon.svg';
import { CompletePaymentBooking } from "../../../redux/actions/completePaymentBooking/CompletePaymentBooking";

const PayLaterSuccessPage = ({ step, isOpen, setIsOpen }) => {
  const screenWidth = useWindowDimensions();
  const [isCancle, setIsCancle] = useState(false)
  const [isFeedBack, setIsFeedBack] = useState(false);
  const [isFeedBackThanks, setIsFeedBackThanks] = useState(false);
  const navigate = useNavigate()
  const { initiateCompletePaymentBooking, completePaymentBookingMutationData } = CompletePaymentBooking()
  const location = useLocation();
  const { slug, status } = useParams()
  const [consentFormLink, setConsentFormLink] = useState("")
  const [isShowFeedbackLink, setIsShowFeedbackLink] = useState(true)
  const data = location?.state;
  const [bookingId, setBookingId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [isThankOpen, setIsThankOpen] = useState(false)
  const [confirmBookingData, setConfirmBookingData] = useState("")

  const paylaterData = useSelector(state => state?.payLaterReducer?.payLaterData)
  const [isSuccess, setIsSuccess] = useState(false);
  const [bookingGqlMessage, setBookingGqlMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setBookingId(+searchParams.get("bookingId"));
    setPaymentId(searchParams.get("ps"));
  }, [searchParams]);


  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  //We get bookingid and paymentid from router state
  useEffect(() => {
    if (bookingId) {
      let tempPaymentId = paymentId ? paymentId : ""
      initiateCompletePaymentBooking({
        bookingId: bookingId,
        paymentId: tempPaymentId
      }, (res) => {
        if (res.success) {
          // console.log(res, "vishal")
          setConfirmBookingData(res?.data)
          setIsShowFeedbackLink(!(res?.data?.review_added))
          setConsentFormLink(res?.data?.consent_form_link)
          setIsSuccess(true)
          setIsLoading(false)
        } else {
          setConsentFormLink(undefined)
          setIsSuccess(false)
          setBookingGqlMessage(res?.message)
          setIsLoading(false)
        }
      }, (e) => {
        setConsentFormLink(undefined)
        setIsSuccess(false)
        setBookingGqlMessage(e?.message)
        setIsLoading(false)
      })
    }
  }, [bookingId, paymentId])

  const handleConsentForm = () => {
    openInNewTab(consentFormLink ? consentFormLink : paylaterData?.consent_form_link)
  }


  const openFeedBackThanksModal = () => {
    setIsFeedBackThanks(true);
    setBookingId(data?.bookingId)
    setIsFeedBack(false); // Close FeedBackModal
  };

  return (
    <>
    <Loader>
    <Header extraClass={''} />
      {
        screenWidth < 768
          ?
          isLoading ?
          <SkeletonLoader type={"PayLaterSuccessPage"} />
          :
          status === "abort" || status === "fail" ?
            <div className="container" style={{marginTop: "70px", padding: '100px 50px'}}>
              <>
                <CloseIcon className="cursor-pointer" onClick={() => navigate("/treatment-cart")} />
                <div className="success-page-conatiner">
                  <img src={FailedIcon} alt="" style={{ width: "100px", height: "100px" }} />
                  <h1>Booking Failed!</h1>
                  <p>Check your email for details. Remember to complete the consent form before your appointment.</p>
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                  </div>
                </div>
              </>
            </div>
            :
            <div className="container" style={{marginTop: "70px", padding: '100px 50px'}}>
              <>
                <CloseIcon className="cursor-pointer" onClick={() => navigate("/treatment-cart")} />
                {
                  isSuccess ?
                    <div className="success-page-conatiner">
                      <SuccessIcon />
                      <h1>Booking Confirmed!</h1>
                      <p>Check your email for details. Remember to complete the consent form before your appointment.</p>
                      <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                        <StepButton blue={true} label={"Complete Consent"} onClick={() => handleConsentForm()} />
                      </div>
                      <h2>How was your experience? </h2>
                      <b onClick={() => setIsFeedBack(true)}>Leave Feedback</b>
                      <h3 onClick={() => setIsCancle(true)}>Cancel this booking</h3>
                    </div>
                  :
                    <div className="success-page-conatiner">
                      <img src={FailedIcon} alt="" style={{ width: "100px", height: "100px" }} />
                      <h1>Booking Failed!</h1>
                      <p>{bookingGqlMessage}</p>
                      <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                      </div>
                    </div>
                }
                <CancleModal isOpen={isCancle} setIsOpen={setIsCancle} bookingId={bookingId} />
                <FeedBackModal isOpen={isFeedBack} setIsOpen={setIsFeedBack} bookingId={bookingId} isThankOpen={isThankOpen} setIsThankOpen={setIsThankOpen} />
                {isFeedBackThanks && <FeedBackThanks isOpen={isFeedBackThanks} setIsOpen={setIsFeedBackThanks} />}
                {isThankOpen && <FeedBackThanks isOpen={isThankOpen} setIsOpen={setIsThankOpen} />}

              </>
            </div>

          :
          status === "abort" || status === "fail" ?
            <div className="container" style={{marginTop: "70px", padding: '100px 50px'}}>
              <>
                <CloseIcon className="cursor-pointer" onClick={() => navigate("/treatment-cart")} />
                <div className="success-page-conatiner">
                  <img src={FailedIcon} alt="" style={{ width: "100px", height: "100px" }} />
                  <h1>Booking Failed!</h1>
                  <p>Check your email for details. Remember to complete the consent form before your appointment.</p>
                  <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                  </div>
                </div>
              </>
            </div> :

            <div className="container" style={{marginTop: "70px", padding: '100px 50px'}}>
              <>
                <CloseIcon className="cursor-pointer" onClick={() => navigate("/treatment-cart")} />
                {
                  isLoading ?
                    <SkeletonLoader type={"PayLaterSuccessPage"} />
                    :
                      isSuccess ?
                        <div className="success-page-conatiner">
                          <SuccessIcon />
                          <h1>Booking Confirmed!</h1>
                          <p>Check your email for details. Remember to complete the consent form before your appointment.</p>
                          <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '50%' }}>

                            <StepButton blue={true} label={"Complete Consent"} onClick={() => handleConsentForm()} />

                          </div>
                          <h2>How was your experience? </h2>
                          {isShowFeedbackLink && <b onClick={() => setIsFeedBack(true)} className="cursor-pointer">Leave Feedback</b>}

                          <h3 onClick={() => setIsCancle(true)} className="cursor-pointer">Cancel this booking</h3>
                        </div>
                      :
                        <div className="success-page-conatiner">
                          <img src={FailedIcon} alt="" style={{ width: "100px", height: "100px" }} />
                          <h1>Booking Failed!</h1>
                          <p>{bookingGqlMessage}</p>
                          <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                          </div>
                        </div>
                }
                
                <CancleModal isOpen={isCancle} setIsOpen={setIsCancle} bookingId={bookingId} />
                <FeedBackModal isOpen={isFeedBack} setIsOpen={setIsFeedBack} openFeedBackThanks={openFeedBackThanksModal} 
                BusinessName={confirmBookingData?.clinic_name} 
                bookingId={bookingId} isThankOpen={isThankOpen} setIsThankOpen={setIsThankOpen} setIsShowFeedbackLink={setIsShowFeedbackLink} />
                {isFeedBackThanks && <FeedBackThanks isOpen={isFeedBackThanks} setIsOpen={setIsFeedBackThanks} />}
                {isThankOpen && <FeedBackThanks isOpen={isThankOpen} setIsOpen={setIsThankOpen} />}

              </>
            </div>

      }
      <Footer />
      </Loader>
    </>
  );
};

export default PayLaterSuccessPage;
