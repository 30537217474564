import { SET_AUTH_FLAG, USER_PROFILE_DATA } from "../../constants";

const initialState = {
    checkAuth: null,
    userData: null,
    userIsProvider: false,

}

const TRAINING_PROVIDER_ROLE = 2;

export const AuthDataReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_AUTH_FLAG:

            return {
                ...state,
                checkAuth: action.payload
            };

        case USER_PROFILE_DATA:
            let userIsProviderUPD = false, userRolesUPD = action.payload && action.payload.userRoles ? action.payload.userRoles : [];
            if (userRolesUPD.indexOf(TRAINING_PROVIDER_ROLE) != -1) {
                userIsProviderUPD = true;
            }
            return {
                ...state,
                userData: action.payload,
                userIsProvider: userIsProviderUPD,
            };

        default:
            return state
    }
}  