import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import "./authforms.scss";
import Cookies from "js-cookie";
import {
  EmailVerifyAction,
  ForgetPasswordDataAction,
  ForgetPasswordVerificationDataAction,
  LoginDataAction,
  ResendVerificationCodeDataAction,
  ResetNewPasswordDataAction,
  SignupDataAction,
} from "../../../redux/actions/auth/Auth";
import eye from "../../../assets/images/icons/eye.svg";
import eyeOff from "../../../assets/images/icons/eye-off.svg";

import { academyCart, clinicCart, termsConditions } from "../../../Routes";
import { GetUserProfileAction } from "../../../redux/actions/auth/user-profile/GetUserProfile";
import { useLocation } from "react-router";
import { ViewAllCartAction } from "../../../redux/actions/treatment/ViewAllCart";
import { ViewAllCourseCartAction } from "../../../redux/actions/courses/ViewAllCourseCart";
import { GetCartItemsCountAction } from "../../../redux/actions/home-page/Academy";
export let clearStates;;

const AuthForms = ({ setTempLoad, tempLoad, setOpen, paymentCallback }) => {
  const [step, setStep] = useState("login"); //step state

  const [passwordType, setPasswordType] = useState("password");
  const [cPasswordType, setCpasswordType] = useState("password");

  const { userProfileQueryData, initUserProfileData } = GetUserProfileAction()


  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (cPasswordType === "password") {
      setCpasswordType("text");
      return;
    }
    setCpasswordType("password");
  };

  //Importing all auth actions
  const { signupData, initSignupDataResponse } = SignupDataAction();
  const { emailVerificationData, initEmailVerificationResponse } =
    EmailVerifyAction();
  const { loginApiData, initLoginDataResponse } = LoginDataAction();
  const { forgetPasswordData, initForgetPasswordResponse } =
    ForgetPasswordDataAction();
  const { resendVerificationCodeData, initResendVerificationCodeResponse } =
    ResendVerificationCodeDataAction();
  const { resetNewPasswordData, initResetNewPasswordResponse } =
    ResetNewPasswordDataAction();

  //for header cart badge and cart items
  const { pathname } = useLocation();
  const { viewAllCartData, initViewAllCartItems } = ViewAllCartAction()
  const { viewAllCourseCartData, initViewAllCourseCartItems } = ViewAllCourseCartAction()

  const { initGetCartItemsCountData } = GetCartItemsCountAction()


  //signup callback functions
  const signupSuceesscallback = () => {
    setStep("email-verification");
  };
  const signupErrorcallback = (data) => {
    if (data?.email_exists && !data?.email_verified)
      setStep("email-verification");
  };
  //email verification callback functions
  const emailVerifySuccess = () => {
    setStep("login");
  };
  const emailVerifyError = () => {
    setOTP("")
  };

  //email verification callback functions
  const forgetEmailSuccess = () => {
    setStep("password-verification");
  };

  //email verification callback functions
  const forgetemailOTPVerifySuccess = () => {
    setStep("reset-password");
  };
  const forgetemailOTPVerifyError = () => {
    setOTP1('')
  };

  // reset mew password callback functions
  const resetNewPasswordSuccess = () => {
    setStep("login");
  };

  //login callback functions
  const loginSuceesscallback = (data) => {
    Cookies.set('faces_login_token', data?.token, { domain: `${window.location?.hostname}` })
    if (paymentCallback != undefined)
      paymentCallback()
    initUserProfileData()
    if (pathname == clinicCart)
      initViewAllCartItems({ referenceType: 1 })
    if (pathname == academyCart)
      initViewAllCourseCartItems()

    initGetCartItemsCountData({
      sessionId: `${sessionStorage.getItem('sessionString')}`
    })
    clearStates()
  };
  const loginErrorcallback = (data) => {
    if (data?.user_email_unverified)
      setStep('email-verification')

  };
  //function for clear the all the states while closing modal
  clearStates = () => {
    setRegisterData({
      fname: "",
      lname: "",
      email: "",
      password: "",
    });
    setLoginData({
      email: "",
      password: "",
    });
    setForgetEmail("");

    setOTP("");
    setOTP1("");

    setResetData({
      password: "",
      password_confirmation: "",
    });

    setRegisterFnameError("");
    setRegisterLnameError("");
    setRegisterEmailError("");
    setRegisterPasswordError("");
    setRegisterTermError("");
    setRegisterTermData(false);

    setLoginEmailError("");
    setLoginPasswordError("");

    setResetPasswordError1("");
    setResetPasswordError2("");

    setForgetEmailError("");

    setOpen(false)
    setStep('login')
    document?.getElementsByClassName('close-icon')[0]?.click();

  }

  // form states
  // ==========login==========
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // ==========signup==========
  const [registerData, setRegisterData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
  });
  // ==========email-verification==========
  const [OTP, setOTP] = useState("");

  // ==========forgot-password==========
  const [forgetEmail, setForgetEmail] = useState("");

  // ==========password-verification==========
  const [OTP1, setOTP1] = useState("");

  // ==========reset-password==========
  const [resetData, setResetData] = useState({
    password: "",
    password_confirmation: "",
  });

  //error states
  // ==========login==========
  const [loginEmailError, setLoginEmailError] = useState("");
  const [loginPasswordError, setLoginPasswordError] = useState("");

  // ==========signup==========
  const [registerFnameError, setRegisterFnameError] = useState("");
  const [registerLnameError, setRegisterLnameError] = useState("");
  const [registerEmailError, setRegisterEmailError] = useState("");
  const [registerPasswordError, setRegisterPasswordError] = useState("");
  const [registerTermError, setRegisterTermError] = useState("");
  const [registerTermData, setRegisterTermData] = useState(false);

  // ==========forgot-password==========
  const [forgetEmailError, setForgetEmailError] = useState("");

  // ==========reset-password==========
  const [resetPasswordError1, setResetPasswordError1] = useState("");
  const [resetPasswordError2, setResetPasswordError2] = useState("");

  //form onChange function
  // ==========login==========
  const handleloginChange = (e) => {
    if (e.target.name == "email") {
      setLoginEmailError("");
    }
    if (e.target.name == "password") {
      setLoginPasswordError("");
    }
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  // ==========signup==========
  const handleSignupChange = (e) => {
    if (e.target.name == "fname") {
      setRegisterFnameError("");
    }

    if (e.target.name == "lname") {
      setRegisterLnameError("");
    }
    if (e.target.name == "email") {
      setRegisterEmailError("");
    }
    if (e.target.name == "password") {
      setRegisterPasswordError("");
    }
    if (e.target.name == "terms") {
      setRegisterTermError("");
    }

    if (e.target.name === "terms") {
      setRegisterTermData(e.target.checked);
    } else {
      setRegisterData({
        ...registerData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // ==========email-verification==========
  const handleChange = (OTP) => {
    setOTP(OTP);
  };

  // ==========forgot-password==========
  const handleForgetChange = (e) => {
    if (e.target.name == "email") {
      setForgetEmailError("");
    }
    setForgetEmail(e.target.value);
  };

  // ==========password-verification==========
  const handleChange1 = (OTP1) => {
    setOTP1(OTP1);
  };

  // ==========reset-password==========
  const handleResetPasswordChange = (e) => {
    if (e.target.name == "password") {
      setResetPasswordError1("");
    }
    if (e.target.name == "password_confirmation") {
      setResetPasswordError2("");
    }
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
  };

  //form submit functions
  // ==========login==========
  const handleLoginForm = (e) => {
    e.preventDefault();

    if (loginData.email === "") {
      setLoginEmailError("Email is required");
    }
    if (
      loginData.email !== "" &&
      !loginData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      setLoginEmailError("Please Enter Valid Email");
    }
    if (loginData.password === "") {
      setLoginPasswordError("Password is required");
    }

    if (
      loginData.email !== "" &&
      loginData.password !== "" &&
      loginData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      loginData['session_id'] = sessionStorage.getItem('sessionString');
      //api call will be here
      initLoginDataResponse(
        loginData,
        loginSuceesscallback,
        loginErrorcallback
      );
      // setStep('')
      // Cookies.set('token', 'dummy ')
      setTempLoad(true);
      // setLogin('dummy')
      // clearStates()
      document?.getElementsByClassName("custom-togller")[0]?.click();
      document?.getElementsByClassName("close-icon")[0]?.click();
    }
  };

  // ==========signup==========
  const handleSignupForm = (e) => {
    e.preventDefault();

    if (registerData.fname === "") {
      setRegisterFnameError("First Name is required");
    }
    if (
      registerData.fname !== "" &&
      !registerData.fname.match(/^[a-z ,.'-]+$/i)
    ) {
      setRegisterFnameError("Please Enter Valid FName");
    }

    if (registerData.lname === "") {
      setRegisterLnameError("Last Name is required");
    }
    if (
      registerData.lname !== "" &&
      !registerData.lname.match(/^[a-z ,.'-]+$/i)
    ) {
      setRegisterLnameError("Please Enter Valid LName");
    }

    if (registerData.email === "") {
      setRegisterEmailError("Email is required");
    }
    if (
      registerData.email !== "" &&
      !registerData.email.match(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      )
    ) {
      setRegisterEmailError("Please Enter Valid Email");
    }
    if (registerData.password === "") {
      setRegisterPasswordError("Password is required");
    }
    if (
      registerData.password !== "" &&
      !registerData.password.match(
        /^.{6,}$/
      )
    ) {
      setRegisterPasswordError(
        "min 6 characters required!"
      );
    }

    if (registerTermData === false) {
      setRegisterTermError("please check the checkbox before submitting");
    }

    if (
      registerData.fname !== "" &&
      registerData.lname !== "" &&
      registerData.email !== "" &&
      registerData.password !== "" &&
      registerData.email.match(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      ) &&
      registerData.password.match(
        /^.{6,}$/
      ) &&
      registerTermData === true
    ) {
      //Api will be call here
      sessionStorage.setItem("email", registerData.email);
      initSignupDataResponse(
        registerData,
        signupSuceesscallback,
        signupErrorcallback
      );
      // setStep('email-verification')
      setTempLoad(true);
    }
  };

  // ==========email-verification==========
  const handleEmailVerification = (e) => {
    e.preventDefault();
    if (OTP.length == 4) {
      //Api will be call here
      initEmailVerificationResponse(
        {
          email: sessionStorage.getItem("email"),
          code: OTP,
        },
        emailVerifySuccess,
        emailVerifyError
      );
      setTempLoad(true);
    }
  };
  const handleResendEmailVericationCode = () => {
    setOTP("");
    //API will be call here
    initResendVerificationCodeResponse({
      email: sessionStorage.getItem("email"),
    });
    setTempLoad(true);
  };

  // ==========forgot-password==========
  const handleForgetForm = (e) => {
    e.preventDefault();

    if (forgetEmail === "") {
      setForgetEmailError("Email is required");
    }
    if (
      forgetEmail !== "" &&
      !forgetEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      setForgetEmailError("Please Enter Valid Email");
    }

    if (
      forgetEmail !== "" &&
      forgetEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      //Api will be call here
      sessionStorage.setItem("email", forgetEmail);
      initForgetPasswordResponse(
        { email: forgetEmail },
        forgetEmailSuccess
      );
      setTempLoad(true);
    }
  };

  // ==========password-verification==========
  const handleOTPVerification = (e) => {
    e.preventDefault();
    if (OTP1.length == 4) {
      //APi will be called here
      initResetNewPasswordResponse(
        {
          email: sessionStorage.getItem("email"),
          code: OTP1,
          changePassword: false
        },
        forgetemailOTPVerifySuccess,
        forgetemailOTPVerifyError
      );
      setTempLoad(true);
    }
  };
  const handlOTPEmailVericationCode = () => {
    setOTP1("");
    //Api will be called here
    initForgetPasswordResponse(
      { email: sessionStorage.getItem('email') },
      function nothingToDo() { }
    );
    setTempLoad(true);
  };

  // ==========reset-password==========
  const handleResetPasswordForm = (e) => {
    e.preventDefault();

    if (resetData.password === "") {
      setResetPasswordError1("Password is required");
    }
    if (resetData.password_confirmation === "") {
      setResetPasswordError2("Confirm Password is required");
    }
    if (
      resetData.password_confirmation !== "" &&
      resetData.password_confirmation !== resetData.password
    ) {
      setResetPasswordError2("password didn't match!");
    }
    if (
      resetData.password !== "" &&
      !resetData.password.match(
        /^.{6,}$/
      )
    ) {
      setResetPasswordError1(
        "min 6 characters required!"
      );
    }
    if (
      resetData.password !== "" &&
      resetData.password.match(
        /^.{6,}$/
      ) &&
      resetData.password_confirmation !== "" &&
      resetData.password_confirmation == resetData.password
    ) {
      //Api will be called Here
      initResetNewPasswordResponse(
        {
          email: sessionStorage.getItem("email"),
          code: OTP1,
          changePassword: true,
          password: resetData.password_confirmation,
        },
        resetNewPasswordSuccess,
        function nothingToDo() { }
      );
      setTempLoad(true);
    }
  };
  // ==========USEEFFECT==========
  useEffect(() => {
    if (tempLoad) {
      if (
        !(
          signupData?.loading ||
          signupData?.loading ||
          loginApiData?.loading ||
          emailVerificationData?.loading ||
          forgetPasswordData?.loading ||
          resendVerificationCodeData?.loading ||
          resetNewPasswordData?.loading
        )
      )
        setTempLoad(false);
    }
  }, [
    tempLoad,
    signupData?.loading,
    emailVerificationData?.loading,
    loginApiData?.loading,
    resendVerificationCodeData?.loading,
    forgetPasswordData?.loading,
    resetNewPasswordData?.loading
  ]);

  return (
    <div>
      <div className="auth-main-div">
        <div className={`${step}-top common-div`}>
          <div className={`${step}-padding common-form-bg-color`}>
            {/* ==========login========== */}
            {step == "login" && (
              <>
                <span className="student-text">Student Portal</span>

                <h5>Login</h5>
                <p>
                  <span className="do-not-text">Don't have an account?</span>
                  <span
                    className="signup-text cursor-pointer ms-2"
                    onClick={() => setStep("signup")}
                  >
                    Signup
                  </span>
                </p>
                <form name="login-form" onSubmit={handleLoginForm}>
                  <div className="input-field-div mt-3 mt-xl-5">
                    <div className="input-field">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={loginData.email}
                        onChange={handleloginChange}
                      />
                      <small className={loginEmailError == "" ? "" : ""}>
                        {loginEmailError}
                      </small>
                    </div>

                    <div className="input-field">
                      <div className="eye-field">
                        <input

                          type={passwordType}
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={loginData.password}
                          onChange={handleloginChange}

                        />
                        <span className="eye" onClick={togglePassword}>
                          {passwordType === "password" ? (
                            <img src={eye} alt="eye" style={{}} />
                          ) : (
                            <img src={eyeOff} alt="eye" style={{}} />
                          )}
                        </span>
                      </div>
                      <small>{loginPasswordError}</small>
                    </div>
                    <div className="forgot-password  my-2">
                      <span
                        className="cursor-pointer"
                        onClick={() => setStep("forgot-password")}
                      >
                        Forgot Password?
                      </span>
                    </div>
                    <button type="submit" className="submit-button mt-4">
                      Login
                    </button>
                  </div>
                </form>
              </>
            )}

            {/* ==========signup========== */}

            {step == "signup" && (
              <>
                <h5>SignUp</h5>
                <p>
                  <span className="do-not-text">Already have an account? </span>
                  <span
                    className="signup-text cursor-pointer"
                    onClick={() => setStep("login")}
                  >
                    Login
                  </span>
                </p>
                <form name="signup-form" onSubmit={handleSignupForm}>
                  <div className="input-field-div">
                    <div className="input-field">
                      <input
                        name="fname"
                        placeholder="First Name"
                        value={registerData.fname}
                        onChange={handleSignupChange}
                      />
                      <small className={registerFnameError == "" ? "my-2" : ""}>
                        {registerFnameError}
                      </small>
                    </div>
                    <div className="input-field">
                      <input
                        type="text"
                        name="lname"
                        placeholder="Last Name"
                        value={registerData.lname}
                        onChange={handleSignupChange}
                      />
                      <small className={registerLnameError == "" ? "my-2" : ""}>
                        {registerLnameError}
                      </small>
                    </div>
                    <div className="input-field">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={registerData.email}
                        onChange={handleSignupChange}
                      />
                      <small className={registerEmailError == "" ? "my-2" : ""}>
                        {registerEmailError}
                      </small>
                    </div>
                    <div className="input-field">
                      <div className="eye-field">
                        <input
                          type={passwordType}
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={registerData.password}
                          onChange={handleSignupChange}
                        />
                        <span className="eye" onClick={togglePassword}>
                          {passwordType === "password" ? (
                            <img src={eye} alt="eye" style={{}} />
                          ) : (
                            <img src={eyeOff} alt="eye" style={{}} />
                          )}
                        </span>
                      </div>
                      <small
                        className={registerPasswordError == "" ? "my-2" : ""}
                      >
                        {registerPasswordError}
                      </small>
                    </div>
                    <div className="input-field checkboxfield ">
                      <div className="row ps-2">
                        <div className="col-1 p-0">
                          <input
                            type="checkbox"
                            name="terms"
                            id="terms"
                            value={registerTermData}
                            onChange={handleSignupChange}
                          />
                        </div>
                        <div className="col-11">
                          <span className="term-condition-text">
                            By clicking here you are agreed to our{" "}
                            <a
                              target="_blank"
                              href={termsConditions}
                              style={{ color: "#0bc9c3" }}
                            >
                              Terms & Condition
                            </a>
                          </span>
                        </div>
                      </div>
                      <small className={registerTermError == "" ? "my-2" : ""}>
                        {registerTermError}
                      </small>
                    </div>

                    <button type="submit" className="submit-button my-2">
                      Signup
                    </button>
                  </div>
                </form>
              </>
            )}

            {/* ==========email-verification========== */}

            {step == "email-verification" && (
              <>
                <h5>Email Verification</h5>
                <p>
                  <span className="do-not-text">
                    {" "}
                    We have sent email verification to{" "}
                    {sessionStorage.getItem("email")}. If not received{" "}
                  </span>
                  <span
                    className="signup-text cursor-pointer"
                    onClick={handleResendEmailVericationCode}
                  >
                    Click Here
                  </span>
                </p>
                <form
                  name="email-verification"
                  onSubmit={handleEmailVerification}
                >
                  <div className="input-field-div">
                    <div className=" inputstylediv">
                      <OTPInput
                        onChange={handleChange}
                        value={OTP}
                        inputStyle="inputStyle"
                        inputType={"number"}
                        numInputs={4}
                        separator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={OTP.length != 4}
                      className="submit-button mt-4 my-2"
                    >
                      Verify{" "}
                    </button>
                  </div>
                </form>
              </>
            )}

            {/* ==========forgot-password========== */}
            {step == "forgot-password" && (
              <>
                <h5>
                  Forgot <br /> Password
                </h5>
                <p>
                  <span className="do-not-text">Remember Password? </span>
                  <span
                    className="signup-text cursor-pointer"
                    onClick={() => setStep("login")}
                  >
                    Login
                  </span>
                </p>
                <form name="forget-password-form" onSubmit={handleForgetForm}>
                  <div className="input-field-div">
                    <div className="input-field">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={forgetEmail}
                        onChange={handleForgetChange}
                      />
                      <small className={forgetEmailError == "" ? "my-2" : ""}>
                        {forgetEmailError}
                      </small>
                    </div>
                    <button type="submit" className="submit-button my-2">
                      Send Code
                    </button>
                  </div>
                </form>
              </>
            )}

            {/* ==========email-verification========== */}

            {step == "password-verification" && (
              <>
                <h5>Verification</h5>
                <p>
                  <span className="do-not-text">
                    {" "}
                    We have sent email verification to{" "}
                    {sessionStorage.getItem("email")}. If not received{" "}
                  </span>
                  <span
                    className="signup-text cursor-pointer"
                    onClick={handlOTPEmailVericationCode}
                  >
                    Click Here
                  </span>
                </p>
                <form
                  name="reset-password-verification"
                  className="reset-password-verification-template pt-2"
                  onSubmit={handleOTPVerification}
                >
                  <div className="input-field-div">
                    <div className=" inputstylediv">
                      <OTPInput
                        onChange={handleChange1}
                        value={OTP1}
                        inputStyle="inputStyle"
                        inputType={"number"}
                        numInputs={4}
                        separator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={OTP1.length != 4}
                      className="submit-button mt-4 my-2"
                    >
                      Verify
                    </button>
                  </div>
                </form>
              </>
            )}

            {/* ==========reset-password========== */}
            {step == "reset-password" && (
              <>
                <h5>
                  Reset <br /> Password
                </h5>
                <p>
                  <span className="do-not-text">Setup your new password!!</span>
                </p>
                <form
                  name="reset-password-form"
                  onSubmit={handleResetPasswordForm}
                >
                  <div className="input-field-div">
                    <div className="input-field">
                      <div className="eye-field">
                        <input
                          type={passwordType}
                          name="password"
                          id="password"
                          placeholder="New Password"
                          value={resetData.password}
                          onChange={handleResetPasswordChange}
                        />
                        <span className="eye" onClick={togglePassword}>
                          {passwordType === "password" ? (
                            <img src={eye} alt="eye" style={{}} />
                          ) : (
                            <img src={eyeOff} alt="eye" style={{}} />
                          )}
                        </span>
                      </div>
                      <small
                        className={resetPasswordError1 == "" ? "my-2" : ""}
                      >
                        {resetPasswordError1}
                      </small>
                    </div>
                    <div className="input-field">
                      <div className="eye-field">
                        <input
                          type={cPasswordType}
                          name="password_confirmation"
                          id="password_confirmation"
                          placeholder="Confirm Password"
                          value={resetData.password_confirmation}
                          onChange={handleResetPasswordChange}
                        />
                        <span className="eye" onClick={toggleConfirmPassword}>
                          {cPasswordType === "password" ? (
                            <img src={eye} alt="eye" style={{}} />
                          ) : (
                            <img src={eyeOff} alt="eye" style={{}} />
                          )}
                        </span>
                      </div>
                      <small
                        className={resetPasswordError2 == "" ? "my-2" : ""}
                      >
                        {resetPasswordError2}
                      </small>
                    </div>
                    <button type="submit" className="submit-button my-2">
                      Submit
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthForms;
