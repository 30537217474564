/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import "./header.scss";
import { ReactComponent as Facebook } from "../../assets/images/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as Call } from "../../assets/images/icons/call.svg";
import { ReactComponent as WhatsApp } from "../../assets/images/icons/whatsapp.svg";
import { isLiveSystem } from "../../config";
import { Helmet } from "react-helmet";
// import  HeaderImage  from "../../assets/images/header/logo.png";
import headerImage from "../../assets/images/header/logo.png";
import hamburger from "../../assets/images/header/hamburger.svg";
import { useLocation, useNavigate } from "react-router";
import { academyHome, clinicHome, contactUs, courseDetails, courseListing, dashboard, indexPattern, treatmentDetails, treatmentListing } from "../../Routes";
import AuthModal from "../auth-modal/AuthModal";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ReactComponent as Close } from "../../assets/images/modal/Close.svg";
import dummyAvatar from '../../assets/images/header/dummyAvatar.jpg'
import { ReactComponent as HeaderArrowRight } from "../../assets/images/header/headerarrowright.svg";
import { cookie_auth, generateRandomString } from "../../App";
import Cookies from "js-cookie";
import cartIcon from '../../assets/images/header/cartIcon.png'
import { academyCart } from "../../Routes";
import { clinicCart } from "../../Routes";
import { useDispatch, useSelector } from "react-redux";
import { SET_AUTH_FLAG, USER_PROFILE_DATA } from "../../redux/constants";
import { GetUserProfileAction } from "../../redux/actions/auth/user-profile/GetUserProfile";
import { ViewAllCartAction } from "../../redux/actions/treatment/ViewAllCart";
import { ViewAllCourseCartAction } from "../../redux/actions/courses/ViewAllCourseCart";
import { GetCartItemsCountAction } from "../../redux/actions/home-page/Academy";
import { generateUrlForTrainingDashboard } from "../../utils/urlHelper";
import Avatar from "react-avatar";


const Header = ({ tempLoad, setTempLoad, extraClass }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [closeHamburger, setcloseHamburger] = useState(false);
    const navigate = useNavigate();
    const { width, height } = useWindowDimensions();
    const { pathname } = useLocation();
    const ref = useRef()
    var isMobile;
    const dispatch = useDispatch()
    const checkAuth = useSelector(state => state?.AuthDataReducer?.checkAuth)
    const userData = useSelector(state => state?.AuthDataReducer?.userData)
    const userIsProvider = useSelector(state => state?.AuthDataReducer?.userIsProvider)

    const { userProfileQueryData, initUserProfileData } = GetUserProfileAction()
    const { courseCartItems } = useSelector(state => state?.ViewAllCourseTreatmentCartReducer)
    const { treatmentCartItems } = useSelector(state => state?.ViewAllTreatmentCartReducer)

    const { viewAllCartData, initViewAllCartItems } = ViewAllCartAction()
    const { viewAllCourseCartData, initViewAllCourseCartItems } = ViewAllCourseCartAction()

    const { initGetCartItemsCountData } = GetCartItemsCountAction()
    const { cartCount } = useSelector(state => (state?.ViewAllCourseTreatmentCartReducer))

    if (width <= 992) {
        isMobile = true;
    } else isMobile = false;

    const handleLogout = () => {
        setTempLoad(true)
        setTimeout(() => {
            setTempLoad(false)
        }, 1500)
        dispatch({
            type: SET_AUTH_FLAG,
            payload: false
        })
        dispatch({
            type: USER_PROFILE_DATA,
            payload: null,
        })
        Cookies.remove('faces_login_token', { domain: `${window.location?.hostname}` })
        Cookies.remove('faces_access_token', { domain: `${window.location?.hostname}` })

        sessionStorage.setItem('sessionString', generateRandomString())
        if (pathname == clinicCart)
            initViewAllCartItems({ referenceType: 1 })
        if (pathname == academyCart)
            initViewAllCourseCartItems()

        initGetCartItemsCountData({
            sessionId: `${sessionStorage.getItem('sessionString')}`
        })
    }

    //for scrolling to top while redirecting to new page
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [pathname]);

    useEffect(() => {
        const handleScroll = () => {
            const currentPosition = window.pageYOffset;
            setScrollPosition(currentPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        function checkIfClickedOutside(event) {
            if (!ref?.current?.contains(event.target)) {
                setcloseHamburger(false);
            }
        }
        document.addEventListener("mouseup", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mouseup", checkIfClickedOutside);
        };
    }, []);

    useEffect(() => {
        if ((Cookies.get('faces_login_token') || Cookies.get("faces_access_token")) && userData == null) {
            initUserProfileData()
        }
    }, [])

    useEffect(() => {
        initGetCartItemsCountData({
            sessionId: `${sessionStorage.getItem('sessionString')}`
        })
    }, [])

    return (
        <> {
            isLiveSystem ? <Helmet>
                <script type="text/javascript">
                    {
                        ` !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '507191495303071');
fbq('track', 'PageView'); `
                    }
                </script>
                <noscript><img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=507191495303071&ev=PageView&noscript=1"
                /></noscript>

            </Helmet> : null
        }
            {isMobile ? (
                <>
                    <div className={`header-div ${extraClass} ${scrollPosition > 10 ? 'extra' : ''} `}>
                        <div className="d-flex justify-content-between align-items-center header-outer-box" >
                            <div className="header-logo">
                                <div
                                    className={`header-image ${extraClass} ${scrollPosition > 10 ? 'extra' : ''} text-end cursor-pointer m-2`}
                                    onClick={() => navigate(indexPattern)}
                                >
                                    <img src={headerImage} alt="header-image" className="headerlogo" width={80} height={80} />
                                    {/* <HeaderImage className="header-svg" /> */}
                                </div>
                            </div>
                            <button
                                className="btn custom-togller"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#staticBackdrop"
                                aria-controls="staticBackdrop"
                            >
                                <div className="header-logo">
                                    <div className="header-image m-2">
                                        <img
                                            src={hamburger}
                                            width={50}
                                            height={50}
                                            alt="hamburger"
                                        />
                                    </div>
                                </div>
                            </button>

                            <div
                                className="offcanvas offcanvas-start w-100 offcanvas-custom "
                                data-bs-backdrop="static"
                                tabindex="-1"
                                id="staticBackdrop"
                                aria-labelledby="staticBackdropLabel"
                            >
                                <div className="offcanvas-header d-flex flex-row-reverse">
                                    <button
                                        type="button"
                                        className="close-icon"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    >
                                        {" "}
                                        <Close />
                                    </button>
                                </div>
                                <div className="offcanvas-body text-white">
                                    <div className="header-items">
                                        <div className="items-list ">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="dropdown-item"
                                                        onClick={() => { document?.getElementsByClassName('close-icon')[0]?.click(); navigate(academyHome) }}
                                                    >
                                                        Academy
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" onClick={() => { document?.getElementsByClassName('close-icon')[0]?.click(); navigate(clinicHome) }}>Clinic</a>
                                                </li>
                                                <div className={cookie_auth && "vertical-divider"}>
                                                    <li>
                                                        <a className="dropdown-item" onClick={() => { document?.getElementsByClassName('close-icon')[0]?.click(); navigate(contactUs) }}>Contact</a>
                                                    </li>
                                                </div>

                                                {/* cart dropdown */}

                                                <hr className="dropdown-item p-0 student-hr" />
                                                <div className="px-2">
                                                    {
                                                        pathname == academyHome || pathname == courseListing || pathname?.split("/")[1] == courseDetails?.split("/")[1] || pathname == academyCart ?

                                                            <li>
                                                                <div className="cart-dropdown-div" onClick={() => { document?.getElementsByClassName('close-icon')[0]?.click(); navigate(courseListing) }}>
                                                                    <div className={`cart-option-div ${cartCount?.courseCount > 0 && 'cart-dot-div'}`}>
                                                                        <img src={cartIcon} alt="" />
                                                                    </div>
                                                                    <span className="ms-3">
                                                                        Book Now
                                                                    </span>
                                                                </div>
                                                            </li> :
                                                            pathname == clinicHome || pathname == treatmentListing || pathname?.split("/")[1] == treatmentDetails?.split("/")[1] || pathname == clinicCart ?


                                                                <li>
                                                                    <div className="cart-dropdown-div cart2-margin" onClick={() => { document?.getElementsByClassName('close-icon')[0]?.click(); navigate(clinicCart) }}>
                                                                        <div className={`cart-option-div ${cartCount?.treatmentCount > 0 && 'cart-dot-div'}`}>
                                                                            <img src={cartIcon} alt="" />
                                                                        </div>
                                                                        <span className="ms-3">
                                                                            Book Now
                                                                        </span>
                                                                    </div>
                                                                </li> :

                                                                <>
                                                                    {/* <li>
                                                                        <div className="cart-dropdown-div" onClick={() => { document?.getElementsByClassName('close-icon')[0]?.click(); navigate(academyCart) }}>
                                                                            <div className={`cart-option-div ${cartCount?.courseCount > 0 && 'cart-dot-div'}`}>
                                                                                <img src={cartIcon} alt="" />
                                                                            </div>
                                                                            <span className="ms-3">
                                                                                Course
                                                                            </span>
                                                                        </div>
                                                                    </li>

                                                                    <li>
                                                                        <div className="cart-dropdown-div cart2-margin" onClick={() => { document?.getElementsByClassName('close-icon')[0]?.click(); navigate(clinicCart) }}>
                                                                            <div className={`cart-option-div ${cartCount?.treatmentCount > 0 && 'cart-dot-div'}`}>
                                                                                <img src={cartIcon} alt="" />
                                                                            </div>
                                                                            <span className="ms-3">
                                                                                Treatments
                                                                            </span>
                                                                        </div>
                                                                    </li> */}
                                                                </>
                                                    }

                                                </div>

                                                {/* when not login */}
                                                {
                                                    !(pathname == clinicHome || pathname == treatmentListing || pathname?.split("/")[1] == treatmentDetails?.split("/")[1] || pathname == clinicCart) &&

                                                    (checkAuth || Cookies.get('faces_login_token') || Cookies.get("faces_access_token") ?
                                                        <>

                                                            <hr className="dropdown-item p-0 student-hr" />
                                                            <div className="px-2">
                                                                <li>
                                                                    <span className="avatar-div"

                                                                        onClick={() => {
                                                                            if (userIsProvider) {
                                                                                // window.open(`${process.env.REACT_APP_SERVER_URL}/`, '_blank')
                                                                                window.open(generateUrlForTrainingDashboard(), "_blank")
                                                                            }
                                                                            else {
                                                                                // window.open(`${process.env.REACT_APP_SERVER_URL}/student-portal-dashboard`, '_blank')
                                                                                window.open(generateUrlForTrainingDashboard('/student-portal-dashboard'), "_blank")
                                                                            }
                                                                        }
                                                                        }
                                                                    >

                                                                        <div className="avatar-image mx-1">
                                                                            <Avatar name={userData?.usr_name} src={userData?.image_url} round size="35" color="#EEEFF3" fgColor="#000" textSizeRatio={2} className="border border-1 border-cyan" />
                                                                            {/* <img src={userData?.image_url} alt="avatar_image" /> */}
                                                                        </div>
                                                                        <span className="avatar-name">{userData?.usr_name}</span>
                                                                    </span>
                                                                </li>
                                                                {/* <div className="avatar-links my-portal mx-1" onClick={() => {
                                                                    if (userIsProvider) {
                                                                        // window.open(`${process.env.REACT_APP_SERVER_URL}/`, '_blank')
                                                                        window.open(generateUrlForTrainingDashboard(), "_blank")
                                                                    }
                                                                    else {
                                                                        // window.open(`${process.env.REACT_APP_SERVER_URL}/student-portal-dashboard`, '_blank')
                                                                        window.open(generateUrlForTrainingDashboard('/student-portal-dashboard'), "_blank")
                                                                    }
                                                                }
                                                                }>
                                                                    <li>

                                                                        <span>
                                                                            My Portal
                                                                        </span>
                                                                        <div className="arraow-image cursor-pointer">
                                                                            <HeaderArrowRight className="right-arrow-svg" />
                                                                        </div>
                                                                    </li>
                                                                </div> */}
                                                                {/* <div className="avatar-links edit-profile mx-1">
                                                                    <li>
                                                                        <span>
                                                                            Edit Profile
                                                                        </span>
                                                                        <div className="arraow-image cursor-pointer">
                                                                            <HeaderArrowRight className="right-arrow-svg" />
                                                                        </div>

                                                                    </li>
                                                                </div> */}
                                                                <div className="avatar-links logout mx-1" onClick={() => handleLogout()}>
                                                                    <li>

                                                                        <span>
                                                                            Logout
                                                                        </span>
                                                                        <div className="arraow-image cursor-pointer">
                                                                            <HeaderArrowRight className="right-arrow-svg" />
                                                                        </div>
                                                                    </li>
                                                                </div>

                                                            </div>

                                                        </> :
                                                        <li>

                                                            <button className="dropdown-item" onClick={() => { setAuthModalOpen(true) }}>
                                                                Student Login
                                                            </button>
                                                        </li>)
                                                }

                                            </ul>
                                        </div>
                                        <div className="social-media-icons">
                                            <div className="mobile-header">
                                                <a
                                                    className="social-media-icon"
                                                    href="https://www.facebook.com/KanvasMedicalGroup"
                                                    target="_blank"
                                                >
                                                    <Facebook />
                                                </a>
                                                <a
                                                    className="social-media-icon"
                                                    href="https://www.instagram.com/kanvasmedicalgroup/"
                                                    target="_blank"
                                                >
                                                    <Instagram />
                                                </a>
                                                <a
                                                    className="social-media-icon"
                                                    href="tel:+123456234123"
                                                >
                                                    <Call />
                                                </a>
                                                <a
                                                    className="social-media-icon"
                                                    href="https://api.whatsapp.com/send/?phone=%2B447398592729&text=Hi&type=phone_number&app_absent=0"
                                                    target="_blank"
                                                >
                                                    <WhatsApp />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>

                    {/* <Fade top={scrollPosition > 80}> */}
                    <div className={`header-div ${extraClass}  ${scrollPosition > 10 ? 'extra' : ''} p-4`}>
                        <ul className="navbar-nav">
                            <div className="py-3 navpading">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4 m-auto">
                                            <li className="nav-item">
                                                <div className="fb-insta-div icon-div">
                                                    <p>Follow</p>
                                                    <hr />
                                                    <div className="iconsWrapper d-flex">
                                                        <div className="fb-image icons">
                                                            <a
                                                                className="social-media-icon"
                                                                href="https://www.facebook.com/KanvasMedicalGroup"
                                                                target="_blank"
                                                            >
                                                                <Facebook />
                                                            </a>
                                                        </div>
                                                        <div className="insta-image icons">
                                                            <a
                                                                className="social-media-icon"
                                                                href="https://www.instagram.com/kanvasmedicalgroup/"
                                                                target="_blank"
                                                            >
                                                                <Instagram />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                        <div className="col-md-4">
                                            <li className="nav-item">
                                                <div className="header-logo">
                                                    <div
                                                        // className="header-image cursor-pointer"
                                                        className={`header-image ${extraClass}  cursor-pointer ${scrollPosition > 10 ? 'extra' : ''}`}
                                                        onClick={() => navigate(indexPattern)}
                                                    >
                                                        <img src={headerImage} alt="header-image" className="headerlogo" height={90} width={280} />
                                                        {/* <HeaderImage className="header-svg" /> */}
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                        <div className="col-md-4 m-auto">
                                            <div className="d-flex justify-content-between align-items-center icon-ham-div">
                                                <li className="nav-item ms-auto">
                                                    <div className="call-whatsapp-div icon-div">
                                                        <p>Call</p>
                                                        <hr />
                                                        <div className="iconsWrapper d-flex">
                                                            <div className="call-image icons">
                                                                <a
                                                                    className="social-media-icon"
                                                                    href="tel:+123456234123"
                                                                >
                                                                    <Call />
                                                                </a>
                                                            </div>
                                                            <div className="whatsapp-image icons">
                                                                <a
                                                                    className="social-media-icon"
                                                                    href="https://api.whatsapp.com/send/?phone=%2B447398592729&text=Hi&type=phone_number&app_absent=0"
                                                                    target="_blank"
                                                                >
                                                                    <WhatsApp />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item me-0">
                                                    <div className="header-logo" ref={ref}>
                                                        <div className="header-image closeIcon" >
                                                            <div className="dropdown">
                                                                <button
                                                                    className={"btn dropdown-toggle custom-togller"}
                                                                    href="#"
                                                                    role="button"
                                                                    id="dropdownMenuLink"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                    onClick={() => setcloseHamburger(!closeHamburger)}
                                                                >
                                                                    {/* <img src={hamburger} alt="hamburger" /> */}
                                                                    <div className="line-container">
                                                                        <div className="line"></div>
                                                                        <div className="line"></div>
                                                                        <div className="line"></div>
                                                                    </div>

                                                                </button>


                                                                <div
                                                                    className={`dropdown-menu headerHamburger ${extraClass}`}
                                                                    aria-labelledby="dropdownMenuLink"
                                                                >
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() => navigate(academyHome)}
                                                                    >
                                                                        Academy
                                                                    </a>
                                                                    <a className="dropdown-item" onClick={() => navigate(clinicHome)} >Clinic</a>
                                                                    <a className="dropdown-item" onClick={() => navigate(contactUs)}>Contact</a>

                                                                    {/* cart section */}
                                                                    <hr className="dropdown-item p-0 student-hr" />
                                                                    <div className="px-2">
                                                                        {
                                                                            pathname == academyHome || pathname == courseListing || pathname?.split("/")[1] == courseDetails?.split("/")[1] || pathname == academyCart ?

                                                                                <div className="cart-dropdown-div" onClick={() => navigate(courseListing)}>
                                                                                    {/* <div className={`cart-option-div ${cartCount?.courseCount > 0 && 'cart-dot-div'}`}>
                                                                                        <img src={cartIcon} alt="" />
                                                                                    </div> */}
                                                                                    <span className="">
                                                                                        Book Now
                                                                                    </span>
                                                                                </div> :
                                                                                pathname == clinicHome || pathname == treatmentListing || pathname?.split("/")[1] == treatmentDetails?.split("/")[1] || pathname == clinicCart ?
                                                                                    <div className="cart-dropdown-div" onClick={() => navigate(clinicCart)}>
                                                                                        {/* <div className={`cart-option-div ${cartCount?.treatmentCount > 0 && 'cart-dot-div'}`}>
                                                                                            <img src={cartIcon} alt="" />
                                                                                        </div> */}
                                                                                        <span className="">
                                                                                            Book Now
                                                                                        </span>
                                                                                    </div>
                                                                                    :
                                                                                    <>

                                                                                        {/* <div className="cart-dropdown-div" onClick={() => navigate(academyCart)}>
                                                                                            <div className={`cart-option-div ${cartCount?.courseCount > 0 && 'cart-dot-div'}`}>
                                                                                                <img src={cartIcon} alt="" />
                                                                                            </div>
                                                                                            <span className="ms-3">
                                                                                                Course
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="cart-dropdown-div" onClick={() => navigate(clinicCart)}>
                                                                                            <div className={`cart-option-div ${cartCount?.treatmentCount > 0 && 'cart-dot-div'}`}>
                                                                                                <img src={cartIcon} alt="" />
                                                                                            </div>
                                                                                            <span className="ms-3">
                                                                                                Treatments
                                                                                            </span>
                                                                                        </div> */}
                                                                                    </>

                                                                        }

                                                                    </div>

                                                                    {
                                                                        !(pathname == clinicHome || pathname == treatmentListing || pathname?.split("/")[1] == treatmentDetails?.split("/")[1] || pathname == clinicCart) &&

                                                                        (checkAuth || Cookies.get('faces_login_token') || Cookies.get("faces_access_token") ?
                                                                            <>

                                                                                {/* <hr className="dropdown-item p-0 student-hr" /> */}
                                                                                <div className="px-2"
                                                                                >
                                                                                    <span className="avatar-div"
                                                                                        onClick={() => {
                                                                                            if (userIsProvider) {
                                                                                                // window.open(`${process.env.REACT_APP_SERVER_URL}/`, '_blank')
                                                                                                window.open(generateUrlForTrainingDashboard(), "_blank")
                                                                                            }
                                                                                            else {
                                                                                                // window.open(`${process.env.REACT_APP_SERVER_URL}/student-portal-dashboard`, '_blank')
                                                                                                window.open(generateUrlForTrainingDashboard('/student-portal-dashboard'), "_blank")
                                                                                            }
                                                                                        }}

                                                                                    >
                                                                                        <div className="avatar-image mx-1">
                                                                                            <Avatar name={userData?.usr_name} src={userData?.image_url} round size="35" color="#EEEFF3" fgColor="#000" textSizeRatio={2} className="border border-1 border-cyan" />
                                                                                            {/* <img src={userData?.image_url} alt="avatar_image" /> */}
                                                                                        </div>
                                                                                        <span className="avatar-name">{userData?.usr_name}</span>
                                                                                    </span>
                                                                                    {/* <div className="avatar-links my-portal mx-1" onClick={() => {
                                                                                        if (userIsProvider) {
                                                                                            // window.open(`${process.env.REACT_APP_SERVER_URL}/`, '_blank')
                                                                                            window.open(generateUrlForTrainingDashboard(), "_blank")
                                                                                        }
                                                                                        else {
                                                                                            // window.open(`${process.env.REACT_APP_SERVER_URL}/student-portal-dashboard`, '_blank')
                                                                                            window.open(generateUrlForTrainingDashboard('/student-portal-dashboard'), "_blank")
                                                                                        }
                                                                                    }
                                                                                    }>
                                                                                        <span>
                                                                                            My Portal
                                                                                        </span>
                                                                                        <div className="arraow-image cursor-pointer">
                                                                                            <HeaderArrowRight className="right-arrow-svg" />
                                                                                        </div>
                                                                                    </div> */}
                                                                                    {/* <div className="avatar-links edit-profile mx-1">
                                                                                        <span>
                                                                                            Edit Profile
                                                                                        </span>
                                                                                        <div className="arraow-image cursor-pointer">
                                                                                            <HeaderArrowRight className="right-arrow-svg" />
                                                                                        </div>
                                                                                    </div> */}
                                                                                    <div className="avatar-links logout mx-1" onClick={() => handleLogout()}>
                                                                                        <span>
                                                                                            Logout
                                                                                        </span>
                                                                                        <div className="arraow-image cursor-pointer">
                                                                                            <HeaderArrowRight className="right-arrow-svg" />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </> :
                                                                            <button className="dropdown-item" onClick={() => setAuthModalOpen(true)}>
                                                                                Student Login
                                                                            </button>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ul>
                    </div>
                    {/* </Fade> */}
                </>
            )
            }
            <AuthModal
                isOpen={authModalOpen}
                setOpen={setAuthModalOpen}
                setTempLoad={setTempLoad}
                tempLoad={tempLoad}
            />
        </>
    );
};

export default Header;
