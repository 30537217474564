import { SET_CART_COUNT } from "../../constants";
import { VIEW_ALL_COURSE_CART_ITEMS } from "../../constants/courses/ViewAllCourseCart";

const initialState = {
    courseCartItems: [],
    totalCoursePrice: null,
    cartCount: null,
    totalDeposit:null,
    totalPayableAmount:null
}

export const ViewAllCourseTreatmentCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_ALL_COURSE_CART_ITEMS:

            // const total = action.payload?.data?.courses.reduce((accumulator, object) => {
            //     return accumulator + object['price'];
            // }, 0);
            // const totalDeposit = action.payload?.data?.courses.reduce((accumulator, object) => {
            //     return accumulator + object['depositAmount'];
            // }, 0);

            const totalPrice=action.payload?.data?.totalPrice
            const totalDeposit=action.payload?.data?.totalDeposit
            const totalPayableAmount=action.payload?.data?.totalPayableAmount

            return {
                ...state,
                courseCartItems: action.payload?.data?.courses,
                totalCoursePrice: totalPrice,
                totalDeposit:totalDeposit,
                totalPayableAmount:totalPayableAmount
            }

        case SET_CART_COUNT:
            return {
                ...state,
                cartCount: action.payload,
            }

        default:
            return state
    }

}
