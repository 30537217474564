import { generatePath } from "react-router";

export const indexPattern = "/";
export const getIndexRoute = () => {
    return generatePath(indexPattern);
};

export const academyHome = "/academy";
export const getacademyHomeRoute = () => {
    return generatePath(academyHome);
};

export const courseListing = "/course-listing";
export const getCourseListingRoute = () => {
    return generatePath(courseListing);
};

export const courseDetails = "/course-details/:slug"
export const getCourseDetailsRoute = (slug) => {
    return generatePath(courseDetails, { slug });
};

export const academyCart = "/course-cart"
export const getAcademyCartRoute = () => {
    return generatePath(academyCart);
};

export const dashboard = "/dashboard"
export const getDashboardRoute = () => {
    return generatePath(dashboard);
};

export const courseModules = "/course-modules/:courseName"
export const getCourseModulesRoute = (courseName) => {
    return generatePath(courseModules, { courseName });
};
export const courseExam = "/course-exam/:courseName"
export const getCourseExamRoute = (courseName) => {
    return generatePath(courseExam, { courseName });
};
export const clinicHome = "/clinic";
export const getclinicHomeRoute = () => {
    return generatePath(clinicHome);
};
export const treatmentListing = "/treatment-listing";
export const gettreatmentListingRoute = () => {
    return generatePath(treatmentListing);
};

export const treatmentDetails = "/treatment-details/:slug"
export const getTreatmentDetailsRoute = (slug) => {
    return generatePath(treatmentDetails, { slug });
};


export const contactUs = "/cms-pages/contact-us"
export const getContactUsRoute = () => {
    return generatePath(contactUs);
};

export const clinicCart = "/treatment-cart"
export const getClinicCartRoute = () => {
    return generatePath(clinicCart);
};

// export const myBookings = "/my-bookings"
// export const getmyBookingsRoute = () => {
//     return generatePath(myBookings);
// };

export const errorPage = "/*"
export const getErrorPageRoute = () => {
    return generatePath(errorPage);
};

//cms-pages

export const aboutUs = "/about-us"
export const getAboutUsRoute = () => {
    return generatePath(aboutUs);
};

export const privacyPolicy = "/privacy-policy"
export const getPrivacyPolicyRoute = () => {
    return generatePath(privacyPolicy);
};

export const termsConditions = "/terms-and-conditions"
export const getTermsConditionsRoute = () => {
    return generatePath(termsConditions);
};

export const clinicCartStatus = "/treatment-cart/:slug/:status"
export const clinicCartStatusPattern = (slug, status) => {
  return generatePath(clinicCartStatus, { slug, status })
}
export const traininglanding = "/training-landing/:pageURl"
export const gettraininglandingRoute = (pageURl) => {
    return generatePath(traininglanding, { pageURl });
};

export const treatmentlanding = "/treatment-landing/:pageURl"
export const gettreatmentlandingRoute = (pageURl) => {
    return generatePath(treatmentlanding,{pageURl});
};
