import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./training-landing-page.scss";

import { GetReviewListAction } from "../../redux/actions/home-page/Academy";
import Slider from "react-slick";
import Avatar from "react-avatar";
import CustomModal from "../../components/modal/CustomModal";
import { FaArrowRightLong, FaWhatsapp } from "react-icons/fa6";

// img

import bannerImg from "../../assets/images/training-landingpage/bannerImg.png";
import arrowbanner from "../../assets/images/training-landingpage/arrow.svg";
import bannerAssets from "../../assets/images/training-landingpage/bannerAssets.png";
import courseImg from "../../assets/images/training-landingpage/courseImg.png";
import arrowright from "../../assets/images/training-landingpage/arrowright.svg";
import training from "../../assets/images/training-landingpage/training.png";
import star from "../../assets/images/training-landingpage/star.svg";
import stargrey from "../../assets/images/training-landingpage/stargrey.svg";
import faces_logo from "../../assets/images/training-landingpage/Faces_logo.png";
import faces_icon from "../../assets/images/training-landingpage/faces_icon.svg";
import googleIconLogo from "../../assets/images/training-landingpage/googleIconLogo.png";
import googleIcon from "../../assets/images/training-landingpage/googleIcon.svg";
import newsone from "../../assets/images/training-landingpage/newsone.png";
import newsTwo from "../../assets/images/training-landingpage/newsTwo.png";
import newsThree from "../../assets/images/training-landingpage/newsThree.png";
import enroll from "../../assets/images/training-landingpage/enroll.png";
import bgblack from "../../assets/images/training-landingpage/bgblack.png";
import bannerBottomAssets from "../../assets/images/training-landingpage/bannerBottomAssets.png";
import firstpost from "../../assets/images/training-landingpage/instagramFeed/firstpost.png";
import Secondpost from "../../assets/images/training-landingpage/instagramFeed/Secondpost.png";
import Thirdpost from "../../assets/images/training-landingpage/instagramFeed/Thirdpost.png";
import bookingGirl from "../../assets/images/training-landingpage/bookingGril.png";
import bannerRightAssets from "../../assets/images/training-landingpage/bannerRight.png";
import team1 from "../../assets/images/training-landingpage/team1.png";
import team2 from "../../assets/images/training-landingpage/team2.png";
import team3 from "../../assets/images/training-landingpage/team3.png";
import team4 from "../../assets/images/training-landingpage/team4.png";
import HappyClients from "../../components/happy-clients/HappyClients";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { academyCart, getCourseDetailsRoute } from "../../Routes";
import AuthModal from "../../components/auth-modal/AuthModal";
// import InstaFeeds from "../../components/instaFeed/InstaFeeds";
import { GetTreatmentsListAction } from "../../redux/actions/treatment-listing";
import traPlaceholder from "../../assets/images/training-landingpage/traPlaceholder.png";
import { GetCourseListingAction } from "../../redux/actions/courses/Listing";
import { config } from "../../config";
import TrainingPages from '../../API/trainingPage.json';




const TrainingLandingPage = () => {
  const navigate = useNavigate()
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const { initCourseListData, courseListQueryData } = GetCourseListingAction()

  useEffect(() => {
    initCourseListData({
        page: 1,
        limit: 4,
        isPopular: true,
        categoryId: null
    })
}, [])


  const { getReviewListQueryData: google, initGetReviewListData } =
    GetReviewListAction();
  const {
    getReviewListQueryData: faces,
    initGetReviewListData: initFacesData,
  } = GetReviewListAction();

  const [isInfinite, setIsInfinite] = useState(true);
  const [isInfinite1, setIsInfinite1] = useState(true);
  const [reviewViewMore, setreviewViewMore] = useState(false);
  const [reviewDataModel, setreviewDataModel] = useState();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const {state}=useLocation();
  let pageURl = useParams().pageURl;

  let [pageData, setPageData] = useState(null);


  const { getTreatmentsListQueryData, initGetTreatmentsListData } = GetTreatmentsListAction()

  useEffect(() => {
    initGetTreatmentsListData({
        page: 1,
        limit: 4,
        treatmentId: null,
        isPopular: false
    })
}, [])

  const openreviewModel = (e, data) => {
    setreviewDataModel(data);
    setreviewViewMore(!reviewViewMore);
    console.log(reviewDataModel, "reviewDataModel");
  };

  useEffect(() => {
    initGetReviewListData({
      reviewType: "google",
    });
    initFacesData({
      reviewType: "faces",
    });
  }, []);

  useEffect(() => {
    if (faces?.data?.getReviewList?.data?.reviews?.length > 2) {
      setIsInfinite(true);
    } else {
      setIsInfinite(false);
    }

    if (google?.data?.getReviewList?.data?.reviews?.length > 2) {
      setIsInfinite1(true);
    } else {
      setIsInfinite1(false);
    }
  }, [
    faces?.data?.getReviewList?.data?.reviews,
    google?.data?.getReviewList?.data?.reviews,
  ]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
      <div className={className} onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M0.294956 1.41L4.87496 6L0.294956 10.59L1.70496 12L7.70496 6L1.70496 -2.62268e-07L0.294956 1.41Z"
            fill="#373C35"
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M7.88498 1.41L3.30498 6L7.88498 10.59L6.47498 12L0.474976 6L6.47498 -2.62268e-07L7.88498 1.41Z"
            fill="#373C35"
          />
        </svg>
      </div>
    );
  }

  var setting = {
    infinite: true,
    arrows: true,
    autoplaySpeed: 1900,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,

          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 200,
      once: true, // Set once to true to trigger animations only once
    });
  }, []);



  useEffect(() => {
    if(pageURl) {
      let selectedPageData = TrainingPages?.Data?.find((item) => item.pageURl == pageURl);
      setPageData(selectedPageData);
    }
  }, [pageURl]);

  console.log("pageData dynamic", pageData);

  if(!pageData) {
    return null;
  }

  return (
    <div className="main-Training-page">
      <Header />

      <section className="bannerTrainingSec">
        <div className="bannerAssets">
          <img src={bannerAssets} alt="" />
        </div>
        <div className="bannerTrainingImg">
                <img
                  src={require('../../assets/images/training-landingpage/innerpage/' + pageData.bannerImg)}
                  alt=""
                  data-aos="fade-up"
                  data-aos-duration="800"
                />
              </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="bannerTrainingContent">
                <h5 data-aos="fade-up" data-aos-duration="700">
                {pageData.bannerTitle}
                </h5>
                <p data-aos="fade-up" data-aos-duration="750">
                {pageData.bannerDesc}
                </p>
                <div className="btnWrapper">
                  <button
                    className="TrainingfillBtn"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    onClick={()=>{
                      navigate(getCourseDetailsRoute(config.MODE_KEY === 'live' ? pageData.livecourseSlug : pageData.courseSlug));
                    }}
                  >
                    Book Your Course
                    {/* <span className="IconBtn">
                      <img src={arrowbanner} alt="" />
                    </span> */}
                  </button>
                  <button
                    onClick={() => {
                      window.open(
                        "https://api.whatsapp.com/send/?phone=%2B447900557596&text=Hi&type=phone_number&app_absent=0",
                        "_blank"
                      );
                    }}
                    className="TrainingOutLineBtn"
                    data-aos="fade-up"
                    data-aos-duration="850"
                  >
                    WhatsApp Us
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              {/* <div className="bannerTrainingImg">
                <img
                  src={require('../../assets/images/training-landingpage/innerpage/' + pageData.bannerImg)}
                  alt=""
                  data-aos="fade-up"
                  data-aos-duration="800"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="courseSec sameSpace pb-0" id="course">
        <div className="container">
          <div className="sectionTitle">
            <h5
              data-aos="fade-up"
              data-aos-anchor="#course"
              data-aos-offset="700"
              data-aos-duration="700"
            >
              {pageData.pagename}
            </h5>
          </div>

          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-8 mb-4"
              data-aos="fade-up"
              data-aos-anchor="#course"
              data-aos-offset="750"
              data-aos-duration="750"
            >
              <div className="CourseBox">
                <div className="CourseImg">
                  <img src={require('../../assets/images/training-landingpage/innerpage/' + pageData.courseImg)} alt="" />
                </div>
                <div className="Coursecontent">
                  <h5>Entry Requirements</h5>
                  <ul>
                  {pageData.EntryRequirementsData.map((item, index)=>{
                      return(
                        item?.EntryRequirementsTitle === "" ? 'none' : <li>{item?.EntryRequirementsTitle}</li>
                      )
                  })}
                  </ul>

                  <h5>About the course</h5>
                    <ul>
                      {pageData.AboutCourseData.map((item, index)=>{
                        return(
                          <li>
                            {item?.AboutTitle.length > 0 && <b> {item?.AboutTitle} : </b>} {item?.AboutDesc}
                          </li>
                        )
                      })}
                    </ul>
                  
                </div>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-12 col-lg-4 "
              data-aos="fade-up"
              data-aos-anchor="#course"
              data-aos-offset="750"
              data-aos-duration="750"
            >
              <div className="CourseForm">
                <h5>Course will cover Modules</h5>

                <ul>
                  {pageData.ModulesData.map((item, index)=>{
                    return(
                    <li>
                      <div className="leftPart">
                        <h6>Module {index + 1 } : {item?.ModuleTitle}</h6>
                        <p>{item?.ModuleDesc}</p>
                      </div>
                      <div className="RightArrow">
                        <img src={arrowright} alt="" />
                      </div>
                    </li>
                    )
                  })}
                </ul>

                <div className="accountDetailsWrapper">
                  <p>Total Amount: £{pageData.Price}</p>
                  <h6>Deposit Amount: £{pageData.Deposit}</h6>

                  <span className="linkbtn" onClick={()=> { setAuthModalOpen(true)}}>Enquire now</span>
                  <div className="btnWrapper">
                    <button className="TrainingfillBtn" 
                    onClick={()=>{
                      navigate(getCourseDetailsRoute(config.MODE_KEY === 'live' ? pageData.livecourseSlug : pageData.courseSlug));
                    }}
                    >Book Course</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="TrainingSec sameSpace" id="Training">
        <div className="container">
          <div className="sectionTitle">
            <h5
              data-aos="fade-up"
              data-aos-anchor="#Training"
              data-aos-offset="700"
              data-aos-duration="700"
            >
              Training courses
            </h5>
          </div>
          <div className="row">
            {courseListQueryData?.data?.getCourseList?.data?.courses?.map((item, index) => {
              return (
                <div
                  className="col-sm-12 col-md-6 col-lg-3"
                  key={index}
                  data-aos="fade-up"
                  data-aos-anchor="#Training"
                  data-aos-offset={600 + index * 100}
                  data-aos-duration={600 + index * 100}
                  onClick={()=>{navigate(getCourseDetailsRoute(item?.courseSlug))}}
                >
                  <div className="TrainingBox">
                      <div className="groupBoxWrapper">
                           <img src={traPlaceholder} alt="" />
                        <div className="groupimgBox">
                          <img src={item?.courseImage} alt="" />
                        </div>
                      </div>
                    <div className="TrainingContent">
                      <h5>{item?.courseName}</h5>
                      <div className="PriceWrapper">
                        <p>Price:</p>
                        <p>£{item?.price}</p>
                      </div>
                        <button className="greenBtn" onClick={()=>{  navigate(getCourseDetailsRoute(config.MODE_KEY === 'live' ? pageData.livecourseSlug : pageData.courseSlug));}}>Book Now</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="TeamSec pt-0">
        <HappyClients />
       
      </section>

      <section className="reviewSec sameSpace bg-black">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2">
              <div className="reviewBoxContent mb-3">
                <img src={faces_logo} alt="" className="logoReview" />
                <ul>
                  {[1, 2, 3, 4, 5].map((i, index) => (
                    <li>
                      <img
                        src={
                          index <
                          faces?.data?.getReviewList?.data?.average_rating
                            ? star
                            : stargrey
                        }
                        alt=""
                      />
                    </li>
                  ))}
                </ul>
                <p>{faces?.data?.getReviewList?.data?.total_reviews} Reviews</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
              <div className="reviewSliderWrappper">
                <Slider {...setting}>
                  {faces?.data?.getReviewList?.data?.reviews.map(
                    (item, index) => {
                      return (
                        <div key={index}>
                          <div className="reviewSliderItemBox">
                            <div className="sliderImgWrapper">
                              <div className="imgBox">
                                {item?.userImage == "" ? (
                                  <Avatar
                                    name={item?.userName}
                                    border="0"
                                    round
                                    size="50"
                                    color="#EEEFF3"
                                    fgColor="#000"
                                    textSizeRatio={2}
                                    className="avtar-image"
                                  />
                                ) : (
                                  <img src={item?.userImage} alt="" />
                                )}
                              </div>
                              <div className="imgContent">
                                <div className="imgleftPart">
                                  <h5>{item?.userName}</h5>
                                  <p>{item?.reviewDate}</p>
                                </div>
                                <div className="imgrightPart">
                                  <img src={faces_icon} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="imgReviewContent">
                              <ul>
                                {[1, 2, 3, 4, 5].map((i, index) => (
                                  <li>
                                    <img
                                      src={
                                        index < item?.userRating
                                          ? star
                                          : stargrey
                                      }
                                      alt=""
                                    />
                                  </li>
                                ))}
                              </ul>
                              <p>{item?.userReview.slice(0, 200)}</p>

                              <span className="viewMoreBtn">
                                {item?.userReview.length >= 200 && (
                                  <span
                                    onClick={(e) => {
                                      openreviewModel(e, item);
                                    }}
                                    className="viewMore"
                                  >
                                    View More
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </Slider>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2 mb-3">
              <div className="reviewBoxContent mb-3">
                <img src={googleIconLogo} alt="" className="logoReview" />
                <ul>
                  {[1, 2, 3, 4, 5].map((i, index) => (
                    <li>
                      <img
                        src={
                          index <
                          google?.data?.getReviewList?.data?.average_rating
                            ? star
                            : stargrey
                        }
                        alt=""
                      />
                    </li>
                  ))}
                </ul>
                <p>
                  {google?.data?.getReviewList?.data?.total_reviews} Reviews
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
              <div className="reviewSliderWrappper">
                <Slider {...setting}>
                  {google?.data?.getReviewList?.data?.reviews.map(
                    (item, index) => {
                      // {googledata.map((item, index) => {
                      return (
                        <div>
                          <div className="reviewSliderItemBox">
                            <div className="sliderImgWrapper">
                              <div className="imgBox">
                                {/* <img src={item?.reviewrImg} alt="" /> */}
                                {item?.userImage == "" ? (
                                  <Avatar
                                    name={item?.userName}
                                    border="0"
                                    round
                                    size="50"
                                    color="#EEEFF3"
                                    fgColor="#000"
                                    textSizeRatio={2}
                                    className="avtar-image"
                                  />
                                ) : (
                                  <img src={item?.userImage} alt="" />
                                )}
                              </div>
                              <div className="imgContent">
                                <div className="imgleftPart">
                                  <h5>{item?.userName}</h5>
                                  <p>{item?.reviewDate}</p>
                                </div>
                                <div className="imgrightPart">
                                  <img src={googleIcon} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="imgReviewContent">
                              <ul>
                                {[1, 2, 3, 4, 5].map((i, index) => (
                                  <li>
                                    <img
                                      src={
                                        index < item?.userRating
                                          ? star
                                          : stargrey
                                      }
                                      alt=""
                                    />
                                  </li>
                                ))}
                              </ul>
                              <p>{item?.userReview.slice(0, 200)}</p>

                              <span className="viewMoreBtn">
                                {item?.userReview.length >= 200 && (
                                  <span
                                    onClick={(e) => {
                                      openreviewModel(e, item);
                                    }}
                                    className="viewMore"
                                  >
                                    View More
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="instagram-FeedSec sameSpace" id="instagram-FeedSec">
        <div className="container">
          
            <div className="InstaFeedsWrapper">
              <InstaFeeds token={'IGQWRNZAWZA0M24wamFyR0lvbGxfdXlDa28yNlMtUWYwVWFLYW5WaXpyckF2OGZAqWjJ0c21tcFJaYk1JdzVMbHpybXdRU2JtelQ1Vk9mU2ZADOUNjOWlmSklwYi1iV3BtZA1ZAUQ1ZAMYlBNank1U2Rqd0wycmFmWlg1eDQZD'} limit={12}/>
          </div>
          <div className="btnWrapper text-center">
            <button
              className="TrainingfillBtn "
              onClick={() => {
                window.open(
                  "https://www.instagram.com/kanvasmedicalgroup/",
                  "_blank"
                );
              }}
            >
              Follow us on instagram
              <span className="IconBtn">
                <FaArrowRightLong />
              </span>
            </button>
          </div>
        </div>
      </section>
    */}

      <section
        className="enrollSec"
        style={{ backgroundImage: `url(${bgblack})` }}
        id="enroll"
      >
        <div className="bannerAssets">
          <img src={bannerBottomAssets} alt="" />
        </div>
        <div className="enrollimg">
                <div className="leftImg">
                  <img src={require('../../assets/images/training-landingpage/innerpage/' + pageData.EnrollImg)} />
                </div>
              </div>
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 col-md-4 ps-0"
              data-aos="fade-up"
              data-aos-anchor="#enroll"
              data-aos-offset="700"
              data-aos-duration="700"
            >
            </div>
            <div
              className="col-sm-12 col-md-8"
              data-aos="fade-left"
              data-aos-anchor="#enroll"
              data-aos-offset="700"
              data-aos-duration="700"
            >
              <div className="enrollcontent">
                <h6>Enroll Now in Our {pageData.pagename}:</h6>
                <div className="btnWrapper">
                  <button className="TrainingfillBtn" onClick={()=>{  navigate(getCourseDetailsRoute(config.MODE_KEY === 'live' ? pageData.livecourseSlug : pageData.courseSlug));}}>
                    Enroll now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqSec sameSpace" id="faq">
        <div className="container">
          <div className="sectionTitle">
            <h5
              data-aos="fade-up"
              data-aos-anchor="#faq"
              data-aos-offset="900"
              data-aos-duration="900"
            >
              Things you might want to know
            </h5>
          </div>

          <div className="faqWrapper">
            <div class="accordion" id="accordionExample">
              {pageData.FAQsData?.map((item, index) => {
                return (
                  <div
                    class="accordion-item"
                    data-aos="fade-up"
                    data-aos-anchor="#faq"
                    data-aos-offset={800 + index * 100}
                    data-aos-duration={800 + index * 100}
                    key={index}
                  >
                    <h2 class="accordion-header" id={index}>
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + index}
                        aria-expanded="false"
                        aria-controls={"#collapse" + index}
                      >
                       {item?.question}
                      </button>
                    </h2>
                    <div
                      id={"collapse" + index}
                      class="accordion-collapse collapse"
                      aria-labelledby={index}
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">{item?.answer}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="Trainingfaq">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <div className="TrainingContent">
                <div className="sectionTitle">
                  <h5>Things you might want to know</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bookingSec " id="booking">
        <div className="bannerLeftAssets">
          <img src={bannerRightAssets} alt="" />
        </div>
        <div className="container">
          {/* <div className="sectionTitle">
            <h5
              data-aos="fade-up"
              data-aos-anchor="#booking"
              data-aos-offset="700"
              data-aos-duration="700"
            >
              Booking and Contact
            </h5>
          </div> */}

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="bookingContent">
                  <h4>For direct inquiries and assistance.</h4>
                  <button
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send/?phone=%2B447900557596&text=Hi&type=phone_number&app_absent=0",
                          "_blank"
                        );
                      }}
                      className="whatsappBtn"
                    >
                       <span className="iconBtn">
                        <FaWhatsapp />
                      </span>
                      WhatsApp here
                    </button>
              </div>
              {/* <div
                className="bookingImg"
                data-aos="fade-left"
                data-aos-anchor="#booking"
                data-aos-offset="700"
                data-aos-duration="700"
              >
                <img src={bookingGirl} alt="" />
                <div className="whatsppWrapper">
                  <p>For direct inquiries and assistance.</p>
                  <div className="btnWrapper">
                    <button
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send/?phone=%2B447900557596&text=Hi&type=phone_number&app_absent=0",
                          "_blank"
                        );
                      }}
                      className="TrainingfillBtn"
                    >
                      WhatsApp here
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-sm-12 col-md-6">
            <div
                className="bookingImg text-center"
                data-aos="fade-left"
                data-aos-anchor="#booking"
                data-aos-offset="700"
                data-aos-duration="700"
              >
                <img src={bookingGirl} alt="" />
                </div>
              {/* <div className="bookingForm">
                <form action="#" method="get">
                  <div
                    className="inputGroup"
                    data-aos="fade-up"
                    data-aos-anchor="#booking"
                    data-aos-offset="700"
                    data-aos-duration="700"
                  >
                    <label for="name">Name</label>
                    <input type="text" className="inputGrid" />
                  </div>
                  <div
                    className="inputGroup"
                    data-aos="fade-up"
                    data-aos-anchor="#booking"
                    data-aos-offset="750"
                    data-aos-duration="750"
                  >
                    <label for="name">Email Address</label>
                    <input type="text" className="inputGrid" />
                  </div>
                  <div
                    className="inputGrid"
                    data-aos="fade-up"
                    data-aos-anchor="#booking"
                    data-aos-offset="800"
                    data-aos-duration="800"
                  >
                    <div className="inputGroup">
                      <label for="name">Date</label>
                      <input type="text" className="inputGrid" />
                    </div>
                    <div
                      className="inputGroup"
                      data-aos="fade-up"
                      data-aos-anchor="#booking"
                      data-aos-offset="850"
                      data-aos-duration="850"
                    >
                      <label for="name">Time</label>
                      <input type="text" className="inputGrid" />
                    </div>
                  </div>
                  <div
                    className="inputGroup"
                    data-aos="fade-up"
                    data-aos-anchor="#booking"
                    data-aos-offset="900"
                    data-aos-duration="900"
                  >
                    <label for="name">Message (optional)</label>
                    <textarea type="text" className="inputGrid" />
                  </div>

                  <button
                    type="submit"
                    className="submitBtn"
                    data-aos="fade-up"
                    data-aos-anchor="#booking"
                    data-aos-offset="950"
                    data-aos-duration="950"
                  >
                    Submit
                  </button>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="detailsSec">
        <div className="container">
          <div className="detailsGrid">
            <div className="detailsItem">
              <h5>Contact</h5>
              <p>
                <span
                  onClick={() => {
                    window.open("tel:44 7900 557596");
                  }}
                >
                +44 7900 557596
                </span>
                {/* /
                <span
                  onClick={() => {
                    window.open("tel:44 51458 54622");
                  }}
                >&nbsp; 44 12963 78612
                </span> */}
              </p>
            </div>
            <div className="detailsItem">
              <h5>Email</h5>
              <p onClick={() => {
                    window.open("mailto:hello@kanvasmedicalgroup.com");
                  }}>hello@kanvasmedicalgroup.com</p>
            </div>
            <div className="detailsItem">
              <h5>Address</h5>
              <p>Unit 28-29, Kanvas Medical Group, Anason court, Staffordshire, ST180GB</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <CustomModal
        modalOpen={reviewViewMore}
        setModalOpen={setreviewViewMore}
        type={"review"}
        className="ReviewMoreDataModel"
        modalBody={
          <>
            <div className="reviewModelWrapper">
              <div className="reviewStarWrapper">
                <ul>
                  {[1, 2, 3, 4, 5].map((i, index) => (
                    <li>
                      <img
                        src={
                          index < reviewDataModel?.userRating ? star : stargrey
                        }
                        alt=""
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="reviewDescWrapper">
                <p>{reviewDataModel?.userReview}</p>
              </div>
              <div className="reviewLogoWrapper">
                <div className="reviewImgWrapper">
                  <div
                    className="reviewImg"
                    onClick={() => {
                      window.open(reviewDataModel?.reviewUrl, "blank");
                    }}
                  >
                    {reviewDataModel?.userImage == "" ? (
                      <Avatar
                        name={reviewDataModel?.userName}
                        border="0"
                        round
                        size="50"
                        color="#EEEFF3"
                        fgColor="#000"
                        textSizeRatio={2}
                        className="avtar-image"
                      />
                    ) : (
                      <img src={reviewDataModel?.userImage} alt="" />
                    )}
                  </div>
                  <div className="reviewContentWrapper">
                    <h5
                      onClick={() => {
                        window.open(reviewDataModel?.reviewUrl, "blank");
                      }}
                    >
                      {reviewDataModel?.userName}
                    </h5>
                    <span
                      onClick={() => {
                        window.open(reviewDataModel?.reviewUrl, "blank");
                      }}
                    >
                      {reviewDataModel?.reviewDate}
                    </span>
                  </div>
                </div>
                <span className="comLogo">
                  <img
                    src={
                      reviewDataModel?.reviewFrom === "faces"
                        ? faces_icon
                        : googleIcon
                    }
                  />
                </span>
              </div>
            </div>
          </>
        }
      />
       <AuthModal
                isOpen={authModalOpen}
                setOpen={setAuthModalOpen}
                // setTempLoad={setTempLoad}
                // tempLoad={tempLoad}
                // setLogin={setLogin}
                // paymentCallback={handleCoursePaymentTab}
            />
    </div>
  );
};

export default TrainingLandingPage;
