import Skeleton from "react-loading-skeleton";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./skeleton-loader.scss";

import backArrow from "../../../assets/images/booking-flow-images/icons/BackArrow.svg";

import { useLocation, useNavigate } from "react-router";
// import { businessDashboardRoutePattern, getAppointmentListRoutePattern } from "../../../Routes";
import { useDispatch } from "react-redux";
// import { clearAppointmentStatus } from "../../redux/actions";

const SkeletonLoader = ({ type, showCircle, isGetStarted = false, isClient = false, isNewAppointment = false }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ListSkeleton = () => {

    return (
      <>
        {
          [...Array(8)]?.map((item, index) => {
            return (
              <div
                className={`d-flex align-items-center justify-content-between ${isClient ? "w-100" : ""} ${isClient ? '' : 'mt-4'} ${isMobile ? "mobile-skeleton" : isNewAppointment ? "modal-skeleton" : "desktop-skeleton"
                  }`}
              >
                <div className="d-flex gap-10 align-items-center">
                  <div
                    className={`d-flex align-items-center justify-content-center ${showCircle ? "me-3" : ""
                      }`}
                  >
                    {showCircle && <Skeleton circle width={60} height={60} />}
                  </div>
                  <div className={"client_details d-block mb-0 p-1"}>
                    <Skeleton width={130} height={20} count={1} />
                    <Skeleton width={150} height={20} count={1} />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {isGetStarted ? (
                    <Skeleton width={50} height={50} />
                  ) : (
                    <Skeleton width={20} />
                  )}
                </div>
              </div>
            )
          })
        }

      </>
    );
  };

  const ListTreatmentSkeleton = () => {
    return (
      [...Array(8)]?.map((item, index) => {
        return <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex gap-10 align-items-center">
            <div className={"client_details d-block mb-0 p-1"}>
              <Skeleton width={100} height={10} count={1} />
              <Skeleton width={100} height={10} count={1} />
              <Skeleton width={170} height={10} count={1} />
              <Skeleton width={50} height={10} count={1} />
            </div>
          </div>
          <div className="d-flex align-items-center">
            {isGetStarted ? (
              <Skeleton width={50} height={50} />
            ) : (
              <Skeleton width={20} />
            )}
          </div>
        </div>
      })
    );
  };

  const CalendarSkeleton = () => {
    // return (
    //   <>
    //       {/* <div className="d-flex align-items-center mb-4 justify-content-between ">
    //       <Skeleton width={20} height={10} />
    //       <Skeleton width={200} height={50} />
    //       <Skeleton width={20} height={10} />
    //       </div> */}
    //       <div className="calendar-skeleton">
    //       {[...Array(7)].map((_, index) => (
    //       <div key={index} className="skeleton-day mb-2">
    //       <Skeleton circle width={40} height={10} />
    //     </div>
    //       ))}
    //     </div>

    //      <div className="calendar-skeleton">
    //     {[...Array(7 * 6)].map((_, index) => (
    //     <div key={index} className="skeleton-day">
    //       <Skeleton circle width={40} height={50} />
    //     </div>
    //   ))}

    // </div>
    //   </>

    // );
    return (
      <div className='calendar-skeleton-wrapper mt-4'>
        <div className='custom-calendar-skeleton'>
          <div className='d-flex flex-wrap justify-content-center'>
            {[...Array(30)].map((tempItem, tempIndex) => {
              return (
                <div className={"date-items"}>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  };

  const TabTreatmentSkeleton = () => {
    return (
      <div className="d-flex align-items-center justify-content-between mt-4">
        <div className="d-flex gap-10 align-items-center">
          <div className={"client_details d-block mb-0 p-1"}>
            <Skeleton width={100} height={30} count={1} style={{ borderRadius: '100px' }} />
          </div>
        </div>
      </div>
    );
  };

  const TimeSkeleton = () => {
    return (
      <div className="d-flex align-items-center justify-content-between mt-4">

        <Skeleton width={100} height={30} count={1} />
        <Skeleton width={20} height={20} count={1} style={{ borderRadius: '100px' }} />

      </div>
    );
  };
  const BusinessStepSkeleton = () => {
    return (
      <div className={"edit-business-content mt-3"}>
        <div className="d-flex align-items-center">
          <div className="me-3">
            <Skeleton circle={true} width={50} height={50} />
          </div>
          <div className="mt-2">
            <Skeleton count={1} width={100} />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="pe-4 edit-dashboard-status">
            <Skeleton count={1} width={50} />
          </div>
          <div className="d-flex align-items-center mt-2">
            <Skeleton width={10} />
          </div>
        </div>
      </div>
    );
  };

  const AppointmentListSkeleton = () => {
    return (
      <div className={`${isClient ? 'w-100 offset-lg-0' : 'w-40 offset-lg-4'} col-lg-4 m-auto`}>
        <div className={`appointment-skeleton d-flex flex-column ${isClient ? 'justify-content-start' : 'justify-content-center'}`}>
          {
            isClient ?
              <div className="d-flex flex-column w-40 p-0 text-left">
                <Skeleton width={100} />
                <Skeleton count={2} width={200} />
                <Skeleton width={100} />
              </div>
              :
              <>
                <Skeleton width={150} />
                <Skeleton count={2} width={380} />
                <Skeleton width={100} />
              </>
          }
        </div>
      </div>
    )
  }

  const PhotoCoverSkeleton = () => {
    return (
      <div className="container">
        <div className="form-group multi-preview">
          <div className="row">
            <div className="col-md-12">
              <Skeleton height={250} style={{ borderRadius: "20px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PhotoOtherSkeleton = () => {
    return (
      <div className="container">
        <div className="form-group multi-preview">
          <div className="row">
            <div className="col-md-4 mt-3">
              <Skeleton
                width={160}
                height={150}
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const AppointmentHeaderSkeleton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation().state
    return (
      <>
        <div className="container-fluid appointment-header-skeleton d-flex flex-column align-items-center">
          <div className='close-back container-fluid d-flex justify-content-between'>
            <Skeleton circle width={48} height={48} />
            <Skeleton width={60} height={40} style={{ borderRadius: "100px" }} />
          </div>
          <Skeleton circle width={80} height={80} />
          <Skeleton width={150} />
          <Skeleton count={2} width={280} />
          <Skeleton width={100} />
        </div>
        <div className="s1 d-flex flex-column">
          <div className="content1 m-auto w-auto">
            <Skeleton width={80} />
            <hr />
            <div className='general-details d-flex justify-content-between mb-5'>
              <div className='d-flex flex-column justify-content-center '>
                <Skeleton width={230} className="mb-2" />
                <Skeleton width={230} />
              </div>
              <div className='user-image-container d-flex'>
                <Skeleton circle width={50} height={50} />
              </div>
            </div>
            <Skeleton width={230} className="mb-2" />
            <hr />
            <Skeleton width={150} className="mb-2" />
            <Skeleton width={230} className="mb-2" />
          </div>
        </div>

      </>

    );
  };

  const TreatmentDetailsSkeleton = () => {

    return (
      <>
        <div className="d-flex flex-column">
          <Skeleton width={"100%"} height={200} />
          <div className="mt-3">
            <Skeleton width={"100%"} height={20} count={1} />
            <Skeleton width={300} height={20} count={1} />
          </div>
          <div className="mt-3">
            <Skeleton width={200} height={10} count={1} />
          </div>
          <div className="mt-3">
            <Skeleton style={{ textAlign: "justify" }} width={"100%"} height={10} count={6} />
          </div>
        </div>
      </>
    );
  };

  const TeamMemberSkeleton = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <Skeleton width={90} circle height={90} />

          <div className="d-flex flex-column align-items-start mt-2">
            <div>
              <Skeleton width={70} height={15} />
            </div>
            <Skeleton width={200} height={12} />

            <div className="d-flex gap-5 justify-content-center w-100 mt-2">
              <Skeleton width={70} height={15} />
              <Skeleton width={70} height={15} />
            </div>
            <Skeleton width={450} height={2} />
          </div>

        </div>
      </div>
    );
  };

  const TeamMemberSkeletonWeb = () => {
    return (
      <div className="d-flex justify-content-between align-items-center MobileSkelTon w-85">
        <div className="d-flex gap-3 justify-content-center align-items-start">
          <Skeleton className='profile' width={60} circle height={60} />
          <div className="d-flex flex-column align-items-start mt-2">
            <div>
              <Skeleton width={200} height={15} />
            </div>
            <Skeleton className="desc" width={300} height={12} />
          </div>

        </div>
          <div className="radio-button mt-0">
            <input type="radio" />
          </div>
      </div>
    );
  };
  const TeamMemberSkeletonMobile = () => {
    return (
      <div className="d-flex justify-content-between align-items-center MobileSkelTon">
        <div className="d-flex gap-3">

          <Skeleton className='profile' width={60} circle height={60} />

          <div className="d-flex flex-column align-items-start mt-2">
            <div>
              <Skeleton width={40} height={15} />
            </div>
            <Skeleton className="desc" width={250} height={12} />

          </div>
          <div className="radio-button mt-3">
            <input type="radio" />
          </div>

        </div>
      </div>
    );
  };
  const ClientProfileSkeleton = () => {
    return <div className="client-main-details-container customeDetailsWrappper">
      <div className="client-name-section">
        <div className="left-name-section">
          <Skeleton width={150} />
          <Skeleton width={150} />
        </div>
        <div className="right-name-section">
          <div className="avatar-icon">
            <Skeleton width={50} height={50} circle={true} />
          </div>
        </div>
      </div>
      <div className="client-content-section">
        <div className="label-header">
          <Skeleton width={50} />
          <Skeleton width={"100%"} height={3} />
          <div className="text-content">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
          <div className="text-content">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
          <div className="text-content">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
          <div className="text-content">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
        </div>
        {/* about section */}
        <div className="label-header">
          <Skeleton width={100} />
          <Skeleton width={"100%"} height={3} />
          <div className="text-content-about">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
          <div className="text-content-about">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
        </div>
        {/* contact section */}
        <div className="label-header">
          <Skeleton width={100} />
          <Skeleton width={"100%"} height={3} />
          <div className="text-content-about">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
          <div className="text-content-about">
            <Skeleton width={100} />
            <Skeleton width={50} />
          </div>
          <div className="text-content-about">
            <Skeleton width={100} />

            <Skeleton width={50} />
            <Skeleton width={100} />
            <Skeleton width={50} />
            <Skeleton width={50} />
          </div>
        </div>
        {/* Personal info section */}
        <div className="label-header">
          <Skeleton width={100} />
          <Skeleton width={"100%"} height={3} />
          <div className="text-content-personal">
            <div className="left-personal-section">
              <div className="status-icon">
                <Skeleton width={50} height={50} circle={true} />
              </div>
              <Skeleton width={70} />
            </div>
            <div className="right-personal-section">
              <Skeleton width={10} height={10} />
            </div>
          </div>
          <div className="text-content-personal">
            <div className="left-personal-section">
              <div className="status-icon">
                <Skeleton width={50} height={50} circle={true} />
              </div>
              <Skeleton width={70} />
            </div>
            <div className="right-personal-section">
              <Skeleton width={10} height={10} />
            </div>
          </div>
          <div className="text-content-personal">
            <div className="left-personal-section">
              <div className="status-icon">
                <Skeleton width={50} height={50} circle={true} />
              </div>
              <Skeleton width={70} />
            </div>
            <div className="right-personal-section">
              <Skeleton width={10} height={10} />
            </div>
          </div>
        </div>

      </div>
    </div>
  }

  const ClientListSkeleton = () => {

    return (
      <>
        {
          [...Array(8)]?.map((item, index) => {
            return (
              <div
                className={`d-flex align-items-center justify-content-between w-100 
                  }`}
              >
                <div className="d-flex gap-10 align-items-center">
                  <div
                    className={`d-flex align-items-center justify-content-center ${showCircle ? "me-3" : ""
                      }`}
                  >
                    {showCircle && <Skeleton circle width={60} height={60} />}
                  </div>
                  <div className={"client_details d-block mb-0 p-1"}>
                    <Skeleton width={130} height={20} count={1} />
                    <Skeleton width={150} height={20} count={1} />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {isGetStarted ? (
                    <Skeleton width={50} height={50} />
                  ) : (
                    <Skeleton width={20} />
                  )}
                </div>
              </div>
            )
          })
        }

      </>
    );
  };


  const CommonSkeleton = () => {
    return (

      [...Array(8)]?.map((item, index) => {
        return (
          <div className="w-100 mt-4">
            <Skeleton height={50} width={"100%"} borderRadius={10} />
          </div>
        )
      }
      )
    );
  };

  const EditClientSkeleton = () => {
    return <div class="CustomeNewClientMain pt-5">
      <div className="sticky-header">
      </div>

      <div className="new-add-client-container w-100 pt-5">
        <div className={`container ${true ? "w-100" : ""}`} style={true !== undefined ? { padding: "10px 110px" } : {}}>
          <div className={`row justify-content-between align-items-start`}>
            <div className={`${true ? "col-lg-6 col-md-6" : "col-lg-12 col-md-12"}`}>

              <div className="left-content">
                <Skeleton width={200} height={10} />
                <div className="title-with-content">
                  <Skeleton width={100} />
                  <Skeleton width={"100%"} className="mt-2" height={3} />
                  {/* {basic info start} */}
                  <div className={`d-flex flex-column error-section`}>
                    <Skeleton width={"100%"} height={50} borderRadius={10} className="mb-3" />

                    <Skeleton width={"100%"} height={50} borderRadius={10} />

                  </div>

                  <div className="d-flex justify-content-center align-items-center m-0">
                    <div className="row justify-content-between m-0 w-100 ">
                      <div className="title-with-content">
                        <Skeleton width={100} style={{ marginLeft: "-10px" }} />
                      </div>
                      <div className="p-0">
                        <Skeleton width={"100%"} height={50} borderRadius={10} />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div className="row w-100 m-auto">
                      <div className="BirthdayWrapper p-0">
                        <div className="BirthdayRow">
                          <div className="col-md-4">
                            <Skeleton width={"100%"} height={50} borderRadius={10} />
                          </div>
                          <div className="col-md-4 ">
                            <Skeleton width={"100%"} height={50} borderRadius={10} />
                          </div>
                          <div className="col-md-4">
                            <Skeleton width={"100%"} height={50} borderRadius={10} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {basic info end} */}
                  <div className="title-with-content">
                    <Skeleton width={100} />
                    <Skeleton width={"100%"} height={3} />
                    <div className={`d-flex flex-column error-section`}>
                      <Skeleton width={"100%"} height={50} borderRadius={10} />
                    </div>
                    <div className="row email-section mt-4">
                      <div className="col-md-3 col-lg-3 mt-2">
                        <Skeleton width={"100%"} height={50} borderRadius={10} />
                      </div>
                      <div className="col-md-9 col-lg-9 mt-2">
                        <Skeleton width={"100%"} height={50} borderRadius={10} />
                      </div>
                    </div>
                    <div className="mt-4 location-container pb-3">
                      <Skeleton width={"100%"} height={50} borderRadius={10} />
                      <div className="w-100">
                        <Skeleton width={"100%"} height={50} borderRadius={10} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              (
                <div className="col-lg-5 col-md-5 " style={{ marginTop: "-33px" }}>
                  <div className="right-content ">
                    <h1>&nbsp;</h1>
                    <div className="title-with-content">
                      <Skeleton width={100} />
                      <Skeleton width={"100%"} height={3} />
                      {/* clientId !== undefined to fincen */}
                      {
                        Array.from(Array(4)).map((item, index) => {
                          return <div className="d-flex  treatment-main-action justify-content-between align-items-center gap-3 mt-4">
                            <div className="treatment-actions-content align-items-center d-flex gap-2">
                              <div className="compliance-img-container">
                                <Skeleton width={50} circle={true} height={50} />
                              </div>
                              <div className="tile-text-section">
                                <Skeleton width={100} height={20} />
                              </div>
                            </div>
                            <Skeleton width={20} height={20} circle={true} borderRadius={10} />
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  }

  const ProfileOverViewSkeleton = () => {
    return <div className="client-profile-container customeClientWrapper">
      <div className="container-fluid bg-header m-0 p-0">
        <div className="client-profile-header customeClientProfileWrapper">
          <div className="avatar-icon">
            <Skeleton width={80} height={80} circle={true} borderRadius={10} />
          </div>
          <Skeleton width={150} height={30} />
          <Skeleton width={100} height={10} />
          {/* <StepButton blue={true} label={"Book appointment"} className={"w-25"} onClick={()=>setIsRebookAppointment(true)}/> */}
          <div className="contact-container">
            <Skeleton width={49} height={49} circle={true} borderRadius={10} />
            <Skeleton width={49} height={49} circle={true} borderRadius={10} />
            <Skeleton width={49} height={49} circle={true} borderRadius={10} />
            <Skeleton width={49} height={49} circle={true} borderRadius={10} />
          </div>
        </div>
      </div>
      <div className="tile-client-container customeClientContainer m-3">
        {
          Array.from(Array(6)).map((item, index) => (
            <div className="tile-list-container mb-3" key={index} >
              <div className="left-client-content">
                <Skeleton width={50} circle={true} height={50} />
                <Skeleton width={100} height={20} />
              </div>
              <div className="right-client-content">
                <Skeleton width={20} height={20} circle={true} borderRadius={10} />
              </div>
            </div>
          )
          )
        }
      </div>

    </div>
  }

  const BookingPageSkeleton = () => {
    return (
      <>
        <div className="d-flex flex-column">
          <Skeleton width={"100%"} height={200} />
          <div className="mt-3">
            <Skeleton width={"100%"} height={20} count={1} />
            <Skeleton width={300} height={20} count={1} />
          </div>
          <div className="mt-3">
            <Skeleton width={200} height={10} count={1} />
          </div>
          <div className="mt-3">
            <Skeleton style={{ textAlign: "justify" }} width={"100%"} height={10} count={6} />
          </div>
        </div>
      </>
    );
  }

  const ColorPickerSkeleton = () => {
    return (
      <div className="d-flex align-items-center justify-content-between gap-2 color-picker">

        <Skeleton width={50} circle height={50} />
        <Skeleton width={50} circle height={50} />
        <Skeleton width={50} circle height={50} />
        <Skeleton width={50} circle height={50} />
        <Skeleton width={50} circle height={50} />
        <Skeleton width={50} circle height={50} />
        <Skeleton width={50} circle height={50} />

      </div>
    );
  };

  const ThankyouPageProductSkeleton = () => {
    return (
      <div className='product-card-container'>
        <Skeleton width={100} height={10} />
        <div className="row">
          {
            [...Array(12)]?.map((item, index) => (
              <div className="col-sm-6 col-md-4" key={index}>
                <label className="card-main-section">
                  <div className='card-img-section'>
                    <div className='card-checkbox'>
                      <Skeleton width={10} height={10} />
                    </div>
                    <Skeleton height={150} width={150} />
                  </div>
                  <div className='card-content-section mt-0'>
                    <Skeleton width={100} height={5} />
                    <Skeleton width={100} height={5} />
                    <Skeleton width={100} height={5} />
                  </div>
                </label>
              </div>
            ))
          }
        </div>
      </div>
    );
  };

  const RenderView = () => {
    switch (type) {
      case "list":
        return <ListSkeleton />;
      case "list-treatment":
        return <ListTreatmentSkeleton />;
      case "business-step":
        return <BusinessStepSkeleton />;
      case "photoCover":
        return <PhotoCoverSkeleton />;
      case "photoOther":
        return <PhotoOtherSkeleton />;
      case "tab":
        return <TabTreatmentSkeleton />
      case "calendar":
        return <CalendarSkeleton />
      case "time":
        return <TimeSkeleton />
      case "appointment":
        return <AppointmentListSkeleton />
      case "appointment-detail":
        return <AppointmentHeaderSkeleton />
      case "treatment-details":
        return <TreatmentDetailsSkeleton />
      case "team-member":
        return <TeamMemberSkeleton />
      case "team-member-mobile":
        return <TeamMemberSkeletonMobile />
      case "team-member-web":
        return <TeamMemberSkeletonWeb />
      case "client-profile":
        return <ClientProfileSkeleton />
      case "client-list":
        return <ClientListSkeleton />
      case "common-list":
        return <CommonSkeleton />
      case "edit-client":
        return <EditClientSkeleton />
      case "profile-overview":
        return <ProfileOverViewSkeleton />
      case "color-picker":
        return <ColorPickerSkeleton />
      case "thankyou-product":
        return <ThankyouPageProductSkeleton />
    }
  };

  return (
    <>
      <RenderView />
    </>
  );
};

export default SkeletonLoader;