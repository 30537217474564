import Princess from '../../assets/images/team-members/Princess.jpg'
import Lyan from '../../assets/images/team-members/Yan.jpg'
import Frechie from '../../assets/images/team-members/Frenchie.jpg'
import ASHLEY from '../../assets/images/team-members/Ash.jpg'
import Harry from '../../assets/images/team-members/Harry.jpg'
import Amelia from '../../assets/images/team-members/Amelia.jpg'
import Kayleigh from '../../assets/images/team-members/Kayleigh.jpg'
import Beckie from '../../assets/images/team-members/Becky.jpg'
import Natalie from '../../assets/images/team-members/Natalie.jpg'
import Sameer from '../../assets/images/team-members/Sameer.jpg'
import Lauren from '../../assets/images/team-members/Lauren.jpg'
import Suzy from '../../assets/images/team-members/Suzy.jpg'
import Ben from '../../assets/images/team-members/Ben.jpg'
import Amar from '../../assets/images/team-members/Amar.jpg'
import Bethany from '../../assets/images/team-members/Bethany.jpg'

export const happyclients = [
    {
        name: "Princess Pagulayan",
        qualification: "",
        profession: "Social Media Manager",
        info: `
        <p>Greetings! I'm Princess, and I proudly wear the hat of a Social Media Manager with a rich history as a content manager for the past six rewarding years. My portfolio is a tapestry of captivating stories, and I couldn't be more thrilled to add the chapters I create for Kanvas Medical Group.</p>
        <p>What makes this role truly special is the seamless blending of my two passions: aesthetics and storytelling. Crafting engaging and informative content that showcases the transformative world of aesthetics feels like a dream come true. From showcasing before-and-after transformations to sharing valuable insights, I take great pride in curating content that resonates with our audience.</p>
        <p>Outside of the digital realm, I cherish every moment spent with my furry companions. There's something magical about the unconditional love they offer, and they are my constant source of joy and comfort. As a self-proclaimed foodie, exploring new culinary delights is my idea of heaven. Whether it's savoring local delicacies or experimenting in my own kitchen, food is an art form that truly captivates me.</p>
        <p>Each day is an opportunity to create, connect, and inspire through social media, and I feel privileged to be a part of the Kanvas Medical Group's journey. With my passion for aesthetics and storytelling, I'm excited to weave narratives that leave a lasting impression on our audience and contribute to the growth and success of this incredible team.
        </p>
        `,
        image: Princess
    },
    {
        name: "Lyan Roxas",
        qualification: "",
        profession: "Operations Manager",
        info: `
        <p>Having been a part of the Kanvas Medical Group for four fulfilling years, I take immense pride in my role as a Client Support specialist. My journey began in the administrative side, where I quickly developed a passion for supporting and guiding new students embarking on their aesthetics careers. Witnessing their growth and success has been incredibly rewarding.</p>
        <p>My dedication and commitment to excellence did not go unnoticed, and I was fortunate enough to be promoted to the position of team leader. Now, I oversee the seamless functioning of our administrative system, ensuring that every aspect runs smoothly and efficiently. It brings me great joy to be a driving force behind the success of our team.</p>
        <p>Outside of my professional life, I am a proud mother of two wonderful children. They bring immense joy and fulfillment to my life, and I cherish every moment with them. During my leisure time, I love engaging in random travel adventures with my family, discovering new restaurants and cuisines, and immersing myself in the captivating worlds of sci-fi and mystery movies.</p>
        <p>I find balance in the blend of my personal and professional life, and each aspect complements the other. As I continue to grow and evolve both as a team leader and a mother, I am grateful for the diverse experiences that have shaped me into the compassionate and driven individual I am today.</p>
        `,
        image: Lyan
    },
    {
        name: "Frechie Macalisang",
        qualification: "",
        profession: "Client Support Lead",
        info: `
        <p>Hi there! I'm Frechie, the Client Support Lead at Kanvas Medical Group, and I can honestly say I have the best job ever. Over the past four years, I've had the pleasure of working alongside a fantastic team of colleagues who feel more like family. Together, we've tackled challenges, celebrated successes, and created a supportive and vibrant work environment.</p>
        <p>One of my main responsibilities is assisting with client acquisition. I find it incredibly rewarding to connect with potential clients, understand their needs, and guide them on their aesthetics journey with us. Additionally, I've been involved in training new staff members, ensuring they feel confident and empowered in their roles.</p>
        <p>I take immense pride in my problem-solving skills. Whenever tensions arise, I'm driven to find innovative solutions that benefit both our team and our valued clients. It's this collaborative spirit and dedication to improvement that keeps our clinic at the forefront of the industry.</p>
        <p>Beyond my professional life, I'm a true enthusiast of life's simple pleasures. Food is my love language, and trying new cuisines brings me immense joy. TV series provide the perfect escape after a long day, and traveling to new places opens my mind and heart. Music is the soundtrack to my life, and a cup of coffee fuels me through the day, keeping me energized and focused. And when I need comfort, there's nothing like indulging in a delightful ice cream treat.</p>
        <p>Life is an adventure, and I'm grateful for every moment—both in and out of the office. I'm excited to continue growing with Kanvas Medical Group and exploring all the wonderful experiences that lie ahead.</p>
        `,
        image: Frechie
    },
    {
        name: "ASHLEY J SIMPSON-DAVIES",
        qualification: "BSc MSc RGN",
        profession: "Co-Founder and Advanced Nurse Practitioner",
        info: `
        <p>As the co-founder of Kanvas Medical Group, I bring a multifaceted background to the forefront of our operations. My journey began as a Bio-Medical Science graduate, which laid the foundation for my venture into the aesthetics industry. Over the past six years, I have successfully performed over 10,000 treatments, each one contributing to my expertise and passion. My continual quest for professional growth led me to complete a Masters in Nursing and Science, and as a practicing advance aesthetics nurse, I've been able to directly contribute to our clients' aesthetic journeys. I  introduced the pioneering 12-point non-surgical rhinoplasty, a testament to our commitment to innovative, client-centered treatments.  </p>
        <p>I currently balance hands-on client care with my recent role in founding and creating Faces Consent, the UK's largest aesthetic software. Despite my transition to this technology-driven initiative, I remain unwavering in my dedication to Kanvas Medical Group's mission of delivering top-tier, client-focused aesthetic services.</p>
        <p>I  Welcome you to Kanvas Medical Group, where we blend science, technology, and aesthetics to create your ideal image.</p>
        `,
        image: ASHLEY
    },
    {
        name: "Dr Harry Mongini",
        qualification: "BA Hons, MPhil, Ph.",
        profession: "Co-Owner and Managing director",
        info: `
        <p>My name is Harry, and I am the proud owner of an aesthetics clinic that stands as a testament to the unexpected paths life can lead us on. With a Ph.D. in an unrelated field, my journey into the world of aesthetics began when I joined the esteemed company, Faces Consent, as an operations manager.</p>
        <p>Though my background may seem unconventional, my passion for aesthetics has always burned brightly within me. As an operations manager at Faces Consent, I found myself immersed in a vibrant industry that fascinated me to the core. I witnessed the transformative power of aesthetic procedures, observing how they could enhance not only one's physical appearance but also their self-esteem and overall well-being.</p>
        <p>Driven by my insatiable curiosity and desire to make a difference, I decided to pursue a new path. Embracing the challenge, I embarked on a journey of learning and growth within the field of aesthetics. I dedicated countless hours to studying the artistry, science, and techniques that underpin the industry.</p>
        <p>Armed with a deep understanding of operational excellence and a relentless pursuit of knowledge, I now stand as the owner of my own aesthetics clinic. My vision is to create a sanctuary where individuals can explore their beauty potential in a supportive and empowering environment.</p>
        <p>My clinic is a testament to my dedication to providing exceptional care and results. Collaborating with a team of talented professionals, we prioritize personalized experiences, tailoring treatments to meet the unique needs and aspirations of each client. We embrace cutting-edge technologies and stay at the forefront of advancements, ensuring that our clients have access to the latest and most effective aesthetic procedures.</p>
        <p>As the owner of an aesthetics clinic, my journey has taught me the power of following one's passion and embracing unexpected opportunities. Every day, I am grateful for the chance to help individuals enhance their confidence and celebrate their natural beauty. It is a privilege to lead my team in delivering exceptional care, knowing that our work has the potential to positively transform the lives of our clients.</p>
        <p>With each client we serve, I am reminded of the incredible journey that led me here and the countless possibilities that lie ahead. As an owner and advocate for aesthetics, I am committed to continual growth, staying abreast of advancements, and providing a space where individuals can discover their own unique beauty and embrace their self-confidence.</p>
        `,
        image: Harry
    },
    {
        name: "Amelia Bailey",
        qualification: "BA Hons",
        profession: "Advanced Practitioner, level 4 and 5 Skin specialist",
        info: `
        <p>My name is Amelia, and I am an advanced aesthetic practitioner dedicated to helping my clients enhance their natural beauty and regain their confidence. With a degree in aesthetics and a passion for injectables, I have honed my skills to provide a wide range of non-surgical treatments.</p>
        <p>
        Throughout my career, I have always believed in a personalised approach to aesthetic medicine. I understand that each individual has unique goals and concerns, and I take the time to listen and understand their specific needs.</p>
        <p>By combining my medical expertise with artistic sensibility, I strive to achieve natural-looking results that enhance my clients' features and boost their self-esteem.</p>
        <p>Client safety and satisfaction are my top priorities. I adhere to the highest standards of hygiene, using state-of-the-art equipment and premium quality products. I believe in providing honest and transparent information about the treatments. Seeing the positive impact I can make in the lives of my clients is incredibly rewarding. Witnessing their increased self-confidence and renewed sense of self is what motivates me to continuously deliver exceptional care.</p>
        `,
        image: Amelia
    },
    {
        name: "Kayleigh Stocker",
        qualification: "BA Hons",
        profession: "Practitioner",
        info: `
        <p>My name is Kayleigh, and I am a dedicated and compassionate aesthetic practitioner. With a specialisation in skincare and aesthetics, I have developed a comprehensive understanding of the intersection between health, beauty, and well-being.</p>
        <p>I have always been passionate about aesthetics and the transformative impact it can have on an individual's life. My approach is rooted in empathy and understanding, as I recognise that every client is unique and has their own set of desires and concerns. I take the time to listen attentively to their needs, ensuring a personalised treatment plan that aligns with their goals.</p>
        `,
        image: Kayleigh
    },
    {
        name: "Beckie Whibley",
        qualification: "BSc RGN",
        profession: "Advanced Nurse Practioner",
        info: `
        <p>Hello, I'm Beckie, an Advanced Aesthetics Nurse Practitioner with a passion for both nursing and aesthetics. My journey in healthcare began in 2013, and over the years, I've honed my skills and knowledge to become a Ward Sister on the Acute Medical Unit in Tunbridge Wells. My experiences as a nurse have provided me with invaluable insights into patient care and empathy, which I carry into my aesthetics practice.</p>
        <p>As a highly trained aesthetics practitioner, I specialize in advanced and technically demanding treatments. My commitment to continuous learning ensures that I stay up-to-date with the latest advancements in the field, allowing me to offer my patients cutting-edge procedures and the highest standard of care.</p>
        <p>Each day, I am humbled by the opportunity to help my patients enhance their natural beauty and boost their confidence. Whether it's intricate facial rejuvenation techniques, precise injectables, or other advanced procedures, I approach each treatment with a delicate touch and a keen eye for detail.</p>
        <p>My dedication to excellence extends beyond the treatment room. I prioritize building genuine connections with my patients, understanding their goals, and tailoring bespoke treatment plans to suit their individual needs.</p>
        <p>Being an Advanced Aesthetics Nurse Practitioner allows me to merge my passion for nursing with my love for aesthetics. It's a gratifying profession, and I am committed to providing exceptional care and life-changing results for each person who walks through my door.</p>
        `,
        image: Beckie
    },
    {
        name: "Natalie Harris",
        qualification: "BA Hons",
        profession: "Advanced Practioner",
        info: `
        <p>Natalee is a highly skilled and experienced aesthetic practitioner with a passion for helping individuals achieve their desired aesthetic goals. With a background in the nhs care sector has honed their expertise in various non-surgical cosmetic procedures, ensuring safe and effective treatments for their patients.</p>
        <p>Natalee has attended numerous workshops, conferences, and advanced courses to stay up-to-date with the latest advancements in the field.</p>
        <p>With a keen eye for detail and a deep understanding of facial anatomy, Natalee takes a personalized approach to each patient's unique needs and desires. She believe in enhancing natural beauty and restoring confidence, rather than creating an artificial appearance. Natalee takes the time to listen to their patients' concerns and goals, ensuring a comprehensive treatment plan that aligns with their expectations.</p>
        `,
        image: Natalie
    },
    {
        name: "Dr Sameer Sultan ",
        qualification: "MbChB",
        profession: "Dr Practitioner and Minor Surgeon MbChB",
        info: `
        <p>My name is Dr. Sameer, and I am a physician who proudly holds a Bachelor's degree in MbChB Medicine and Surgery from the prestigious University of Bristol. Throughout my medical education and training, I have acquired a comprehensive understanding of human physiology, pathology, and the art of surgical techniques.</p>
        <p>While I am well-versed in various aspects of aesthetics, my true passion lies in the field of hair transplants. I have dedicated countless hours to perfecting my skills and expertise in this transformative procedure. Hair loss can be a distressing experience for individuals, affecting their self-esteem and overall well-being. I believe that restoring one's hair can have a profound impact on their confidence and quality of life.</p>
        <p>With my specialized training and knowledge in hair transplant techniques, I am able to provide personalized solutions tailored to each patient's unique needs. I stay up-to-date with the latest advancements in the field and employ state-of-the-art technology to deliver exceptional results. Whether it's Follicular Unit Transplantation (FUT) or Follicular Unit Extraction (FUE), I am well-versed in both techniques and carefully evaluate each patient to determine the most suitable approach.</p>
        <p>I am committed to providing a compassionate and supportive environment for my patients. I understand the emotional journey they embark on and aim to alleviate any concerns or anxieties they may have. By fostering open communication and building a strong doctor-patient relationship, I ensure that my patients feel heard, understood, and empowered throughout the entire process.</p>
        <p>As a dedicated professional, I take immense pride in helping individuals regain their confidence and achieve their desired hair restoration goals. My ultimate aim is to deliver natural and aesthetically pleasing results that not only transform appearances but also positively impact lives.</p>
        `,
        image: Sameer
    },
    {
        name: "Lauren Rivett",
        qualification: "BSc RGN",
        profession: "Advanced Nurse Prescriber, Practitioner and Trainer",
        info: `
        <p>Hi! I'm Lauren, an Advanced Aesthetics Nurse Practitioner and Trainer, and my journey in healthcare has been nothing short of remarkable. Starting my career in a hospital-based setting provided me with a solid foundation in patient care, but my true passion lies in the world of aesthetics.</p>
        <p>As an aesthetics practitioner, I take great pride in delivering treatments that enhance and rejuvenate the skin and facial structure while ensuring natural and subtle results. My approach is centered around providing my clients with a refreshed and revitalized appearance, while still maintaining their unique beauty.</p>
        <p>As a Trainer, my goal is to empower and uplift aspiring aesthetics practitioners. I believe that education and support are the cornerstones of a successful career in aesthetics. I'm committed to helping my students feel competent, confident, and well-equipped to excel in their chosen field. Witnessing my students grow and thrive in their aesthetics journey brings me immense joy.</p>
        <p>Outside of work, you can find me enjoying quality time with loved ones or immersing myself in creative pursuits. The world of aesthetics is ever-evolving, and I'm dedicated to staying at the forefront of the industry to provide my clients with the latest and most effective treatments.</p>
        <p>Being an Advanced Aesthetics Nurse Practitioner and Trainer is a fulfilling calling, and I cherish the opportunity to make a positive impact on the lives of my clients and students alike. Together, let's embark on a journey of growth, empowerment, and natural beauty.</p>
        `,
        image: Lauren
    },
    {
        name: "Suzy Kalman",
        qualification: "",
        profession: "Advanced Practitioner, Trainer and Partner at Kanvas Medical Group Bromley",
        info: `
        <p>I am Suzy, a partner and the lead practitioner at Kanvas Medical Group Bromley. For the past seven years, I have dedicated myself to the field of aesthetics, honing my skills and becoming a trusted professional in the industry. Additionally, for the past three years, I have had the privilege of teaching aesthetics, sharing my knowledge and expertise with aspiring practitioners.</p>
        <p>My specialization lies in a range of advanced treatments, including injectables, skincare treatments, IV drips, PRP treatments, microblading, SPMU, and plasma fibroblast treatment. These modalities have allowed me to offer comprehensive solutions to my clients, addressing their unique concerns and helping them achieve their desired outcomes.</p>
        <p>What truly sets me apart is my genuine passion for helping others. I approach each client with empathy and understanding, taking the time to listen and truly grasp their goals. By doing so, I can develop personalized treatment plans that cater to their specific needs, ensuring optimal results.</p>
        <p>My commitment to excellence drives me to stay at the forefront of the industry, constantly learning and growing. I believe that education is key, which is why I am proud to pass on my knowledge and experience as an aesthetics teacher. Guiding and inspiring others in their journey to become skilled professionals brings me immense joy.</p>
        <p>As a partner and lead practitioner, I strive to create a warm and welcoming environment for my clients. Trust and open communication are of utmost importance to me, allowing us to collaborate and achieve the best possible outcomes.</p>
        <p>I am honored to be a part of the Kanvas Medical Group Bromley team, where we share a common goal of helping individuals embrace their true beauty and feel their best. It is a privilege to witness the positive impact our treatments have on our clients' lives, and I look forward to continuing to provide exceptional care and expertise in the field of aesthetics.</p>
        `,
        image: Suzy
    },
    {
        name: "Ben O’brien",
        qualification: "",
        profession: "Co-Founder and Head of Business development",
        info: `
        <p>Hello, I'm Ben, a seasoned professional in the world of technology and aesthetics, and the proud co-founder of Kanvas Medical Group. From my early career days, I've been fascinated by the u,tapped potential at the crossroads of aesthetics. technology, and patient care. This passion paved the way for the creation of Kanvas, the embodiment of my belief in collaboration, innovation, and automated efficiency in aesthetics.</p>
        <p>From the start, I knew that the journey of building a business could never be a solo endeavor. I've always viewed collaboration not just as an option, but as a necessity, a fundamental component of growth. It's through the collective efforts of our diverse team and the relationships we've built with our partners around the globe that Kanvas Medical Group has transformed from a vision into a dynamic reality.</p>
        <p>My journey in the aesthetics industry started with an understanding of the unique challenges it presented. The need for efficiency and safety was apparent, but there was a missing link - a holistic approach that harnessed technology to simplify processes and improve patient outcomes. The idea of Faces Consent was born from this need . I envisioned a platform that would merge aesthetics and technology to create a seamless experience, from appointment booking to aftercare.</p>
        <p>The development of our solutions has been a journey of continual innovation. Through the use of automated systems, we've streamlined the patient journey, revolutionizing the way practitioners operate. It's more than just creating efficiency; it's about empowering practitioners to focus on their core expertise - providing top-tier care for their patients.</p>
        <p>I firmly believe that technology and aesthetics, when harmoniously integrated, can provide a superior patient experience. This belief is the driving force behind Kanvas's mission and serves as our guiding principle as we continue to innovate.</p>
        <p> I am humbled by the progress we've made, but also excited about the future. Each day brings new opportunities for innovation, new ways to enhance safety and efficiency in the industry, and new partnerships that contribute to our growth.</p>
        <p>As we look to the future, I'm dedicated to maintaining our ethos of collaboration and innovation, and I'm excited to continue working with our talented team and partners to push the boundaries of what's possible in aesthetic medicine. Through our collective efforts, we will continue to transform the industry, one innovative solution at a time.</p>
        `,
        image: Ben
    },
    {
        name: "Dr Amar Mohammed",
        qualification: "MBChB, BSc Clin Sci, MRCP",
        profession: "Medical Director and Consultant",
        info: `
       <p>Hello, I am Dr. Amar Muhammad, a dedicated medical professional with a strong background in Acute Medical and General Medicine. I hold an MBChB (hons) degree, a BSc Clin Sci (Hons) qualification, and I am a proud member of the MRCP community.</p>
       <p>While I excel in the field of medicine, my passion extends beyond traditional practice. I am also an experienced and skilled Aesthetics Consultant, specialising in various aesthetic procedures.</p> 
       <p>The balance between my expertise in Acute Medicine and my commitment to aesthetics allows me to provide comprehensive care to my patients. With a keen eye for detail and a deep understanding of the human body, I aim to achieve natural and harmonious results in the realm of aesthetics.</p> 
       <p>I take pride in helping my clients enhance their natural beauty and boost their self-confidence. My approach is grounded in professionalism, ensuring that each patient receives personalised treatment plans tailored to their specific needs and desires.</p> 
       `,
        image: Amar
    },
    {
        name: "Dr Bethany Fryar ",
        qualification: "MBChB",
        profession: "Level 7 Facial aesthetics Practitioner",
        info: `
      <p>Hello, I'm Dr. Bethany, a devoted medical professional currently undergoing GP training and a qualification in Level 7 diploma in facial aesthetics.</p>
      <p>Facial aesthetics and skin rejuvenation are my true passions, and I am here to assist you in achieving your goals of subtly reversing the signs of aging. I firmly believe in enhancing natural beauty and restoring confidence, ensuring my clients leave feeling refreshed and revitalised.</p>
      <p>I promise a professional and warm experience, placing your comfort and satisfaction as my top priorities. My caring and compassionate approach will make your journey towards youthful radiance and improved self-esteem an enjoyable and rewarding one.</p>
      <p>If you are seeking a facial aesthetics expert who is not only highly qualified but genuinely passionate about helping you embrace your natural beauty, I am here for you. Together, we can embark on a transformative journey to unlock your radiance and embrace a more youthful version of yourself. Let's work together to achieve your aesthetic goals in the most subtle and natural way possible.</p>
       `,
        image: Bethany
    }
]