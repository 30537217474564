import React, { Fragment, useState } from "react";
import "./happyclients.scss";
import { Carousel } from "react-bootstrap";
import dummyClient from "../../assets/images/happy-clients/team.png";
import { ReactComponent as Facebook } from '../../assets/images/icons/footer/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/icons/footer/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/images/icons/footer/twitter.svg';
import { happyclients } from './happyClientData'


const HappyClients = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <div className="carouselWrapper container-xxl">
                <Carousel
                
                    indicators={false}
                    activeIndex={index}
                    onSelect={handleSelect}
                >
                    {happyclients.map((item, index) => {
                        return (
                            <Carousel.Item>
                                <div className="carouselContent">
                                    <div className="container-xxl">
                                        <div className="row">
                                            <div className="col-md-6 px-0">
                                                <div className="d-block w-100">
                                                    <div className="image-bg">

                                                        <img
                                                            className="d-block"
                                                            src={item.image}
                                                            alt="First slide"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex justify-content-between flex-column h-100">
                                                    <div className="carouselInfo">
                                                        <div className="d-flex flex-column">
                                                            <h4 className="name my-1 my-lg-2">{item.name}</h4>
                                                            {
                                                                item?.qualification != "" &&
                                                                <span className="qualification my-1 my-lg-2">{item?.qualification}</span>
                                                            }
                                                            <span className="profession my-1 my-lg-2">{item.profession}</span>
                                                        </div>
                                                        <div className="line-div"></div>
                                                        <p className="content-p mt-3" dangerouslySetInnerHTML={{ __html: (item?.info) }}></p>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        </>
    );
};

export default HappyClients;
