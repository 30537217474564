export const apiConfigUrl = process.env.REACT_APP_GRAPHQL_URL;

export const frontendURL = process.env.REACT_APP_LIVE_URL;


export const config = {

    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    MODE_KEY: process.env.REACT_APP_REACT_URL_MODE,

};

export const isLiveSystem = typeof window != 'undefined' && window.location.host == 'www.kanvasmedicalgroup.com' ? true : false