import { gql, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { GET_TREATMENT_LISTING_CONST } from "../../constants";


//====================TREATMENT LISTING====================

//FOR CART PAGE 

const GET_TRETMENT_LISTING_QUERY = gql`
query GetTreatments($page: Int, $limit: Int, $treatmentId: Int, $isPopular: Boolean, $categoryIds: [Int]) {
  getTreatments(page: $page, limit: $limit, treatment_id: $treatmentId, is_popular: $isPopular, category_ids: $categoryIds) {
    success
    message
    data {
      categoryWiseTreatments {
        categoryName
        categoryId
        treatments {
          treatment_id
          treatment_name
          treatment_image
          treatment_price
          treatment_description
          treatment_deposit
          treatment_slug
          treatment_clinics
          treatment_duration
          treatment_images
          average_review_rating
          total_review_count
        }
      }
      categories {
        categoryId
        categoryName
      }
      total
    }
  }
}
`

export const GetTreatmentListingAction = () => {
  const dispatch = useDispatch()

  const [getTreatmentListData, treatmentListData] = useLazyQuery(
    GET_TRETMENT_LISTING_QUERY,
    //api response will be generated here!
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getTreatments: responseData }) => {

        // dispatch({
        //   type: GET_TREATMENT_LISTING_CONST,
        //   payload: responseData
        // })

        if (responseData.success) {
          // toast.success(responseData?.message)
        } else {
          toast.error(responseData?.message)

        }
      }
    }
  )

  //this will be called from components
  const treatmentListQueryData = treatmentListData //variable

  const initTreatmentListData = (data) => {

    getTreatmentListData({
      variables: data
    })
  }

  return { initTreatmentListData, treatmentListData }
}

// ==============================================================


//====================GET TREATMENTS LIST====================

//FOR LISTING PAGE

const GET_TREATMENTS_LIST = gql`
query GetTreatmentsList($page: Int, $limit: Int, $treatmentId: Int, $isPopular: Boolean, $categoryIds: [Int]) {
  getTreatmentsList(page: $page, limit: $limit, treatment_id: $treatmentId, is_popular: $isPopular, category_ids: $categoryIds) {
    success
    message
    data {
      items {
        treatment_id
        treatment_name
        treatment_image
        treatment_price
        treatment_description
        treatment_deposit
        treatment_slug
        treatment_clinics
        treatment_duration
      }
      total
    }
  }
}
`;

export const GetTreatmentsListAction = () => {
  const dispatch = useDispatch();
  const [
    GetTreatmentsListResponseFunction,
    getTreatmentsListResponseData,
  ] = useLazyQuery(GET_TREATMENTS_LIST, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getTreatmentsList: responseData }) => {

      dispatch({
        type: GET_TREATMENT_LISTING_CONST,
        payload: responseData
      })
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getTreatmentsListQueryData = getTreatmentsListResponseData; //variable

  const initGetTreatmentsListData = (data) => {
    GetTreatmentsListResponseFunction({
      variables: data,
    });
  };

  return { getTreatmentsListQueryData, initGetTreatmentsListData };
};
// ==============================================================


//====================TREATMENT DETAILS====================

export const GET_TREATMENT_DETAILS = gql`
query GetTreatmentDetails($slug: String!, $getTreatmentDetailsId: Int) {
  getTreatmentDetails(slug: $slug, id: $getTreatmentDetailsId) {
    success
    message
    data {
      treatment_details {
        treatment_id
        treatment_name
        treatment_image
        treatment_images {
          image_id
          image_url
        }
        treatment_price
        treatment_description
        treatment_duration
        treatment_deposit
      }
    }
  }
}`

export const GetTreatmentDetailsAction = () => {
  const [getTreatmentDetailsResponseFunction, treatmentDetailsResponseData] = useLazyQuery(
    GET_TREATMENT_DETAILS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      onCompleted: ({ getTreatmentDetails: responseData }) => {
      }
    }
  )
  //this will be called from components
  const treatmentDetailsData = treatmentDetailsResponseData //variable

  const initTreatmentDetailsData = (data) => {
    getTreatmentDetailsResponseFunction({
      variables: data
    })
  }
  return { treatmentDetailsData, initTreatmentDetailsData }
}

// ==============================================================



// ========================GET PROVIDER CATEGORY LIST========================

const GET_PROVIDER_CATEGORY_LIST_QUERY = gql`
query GetProviderCategoryList {
  getProviderCategoryList {
    success
    message
    data {
      categories {
        categoryId
        categoryName
      }
    }
  }
}
`;

export const GetProviderCategoryListAction = () => {
  const dispatch = useDispatch();
  const [
    GetProviderCategoryListResponseFunction,
    getProviderCategoryListResponseData,
  ] = useLazyQuery(GET_PROVIDER_CATEGORY_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    onCompleted: ({ getProviderCategoryList: responseData }) => {
      if (responseData.success) {
        // toast.success(responseData?.message)
      } else {
        // toast.error(responseData?.message)
      }
    },
  });
  //this will be called from components
  const getProviderCategoryListQueryData = getProviderCategoryListResponseData; //variable

  const initGetProviderCategoryListData = (data) => {
    GetProviderCategoryListResponseFunction({
      variables: data,
    });
  };

  return { getProviderCategoryListQueryData, initGetProviderCategoryListData };
};

// ==============================================================================

