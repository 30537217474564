import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_CLINICS, VIEW_ALL_CART_ITEMS } from "../../constants/treatment/ViewAllCart";
import Cookies from "js-cookie";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { VIEW_ALL_COURSE_CART_ITEMS } from "../../constants/courses/ViewAllCourseCart";

// =====================VIEW ALL CART=====================

const VIEW_ALL_COURSE_CART_QUERY = gql`
query GetCourseCartItems($sessionId: String) {
    getCourseCartItems(session_id: $sessionId) {
      success
      message
      data {
        courses {
          cartItem_id
          name
          image
          price
          courseSlug
          courseType
          courseTypeReadable
          depositAmount
        }
        totalPrice
        totalDeposit
        totalPayableAmount
      }
    }
  }
`

export const ViewAllCourseCartAction = () => {
    const dispatch = useDispatch()
    const [getViewAllCourseCartResponseFunction, viewAllCourseCartResponseData] = useLazyQuery(
        VIEW_ALL_COURSE_CART_QUERY,
        {
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-and-network",
            onCompleted: ({ getCourseCartItems: responseData }) => {
                dispatch({
                    type: VIEW_ALL_COURSE_CART_ITEMS,
                    payload: responseData
                })
            }
        }
    )
    //this will be called from components
    const viewAllCourseCartData = viewAllCourseCartResponseData //variable

    const initViewAllCourseCartItems = () => {

        //checking guest or logged in user
        let checkTokenLength = Cookies.get('faces_login_token')?.length
        let sessionString
        if (checkTokenLength > 0) {
            sessionString = sessionStorage.getItem('sessionString')
        } else {
            sessionString = sessionStorage.getItem('sessionString')
        }
        getViewAllCourseCartResponseFunction({
            variables: { sessionId: sessionString }
        })
    }


    return { viewAllCourseCartData, initViewAllCourseCartItems }
}

// =========================================================
