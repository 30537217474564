import React, { useEffect, useState } from "react";
import "./treatment-landing-page.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";

// Images
import bannerimage from "../../assets/images/treatment/banner-img.png";
import groupimg from "../../assets/images/treatment/group.png";
import star from "../../assets/images/treatment/star.svg";
import stargrey from "../../assets/images/treatment/stargrey.svg";
import faces_logo from "../../assets/images/treatment/Faces_logo.png";
import faces_icon from "../../assets/images/treatment/faces_icon.svg";
import googleIconLogo from "../../assets/images/treatment/googleIconLogo.png";
import googleIcon from "../../assets/images/treatment/googleIcon.svg";
import { GetReviewListAction } from "../../redux/actions/home-page/Academy";
import Slider from "react-slick";
import Avatar from "react-avatar";
import CustomModal from "../../components/modal/CustomModal";
import HappyClients from "../../components/happy-clients/HappyClients";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation, useNavigate,useParams } from "react-router";
import { clinicCart, getTreatmentDetailsRoute, gettraininglandingRoute, gettreatmentListingRoute, gettreatmentlandingRoute } from "../../Routes";
// import InstaFeeds from "../../components/instaFeed/InstaFeeds";
import TreatmentPages from '../../API/treatmentPage.json';
// import { GetTreatmentsListAction } from "../../redux/actions/treatment-listing";
import traPlaceholder from "../../assets/images/treatment/traPlaceholder.png";
import { config } from "../../config";

console.log(TreatmentPages?.Data[0]?.bannerTitle, 'TreatmentPages')

// import url from "socket.io-client/lib/url";

const TreatmentLandingPage = () => {
  const { getReviewListQueryData: google, initGetReviewListData } =
    GetReviewListAction();
    const navigate = useNavigate()
  const {
    getReviewListQueryData: faces,
    initGetReviewListData: initFacesData,
  } = GetReviewListAction();

  const [isInfinite, setIsInfinite] = useState(true);
  const [isInfinite1, setIsInfinite1] = useState(true);
  const [reviewViewMore, setreviewViewMore] = useState(false);
  const [reviewDataModel, setreviewDataModel] = useState();

  let pageURl = useParams().pageURl;

  let [pageData, setPageData] = useState(null);


  const openreviewModel = (e, data) => {
    setreviewDataModel(data);
    setreviewViewMore(!reviewViewMore);
    console.log(reviewDataModel, "reviewDataModel");
  };

  useEffect(() => {
    initGetReviewListData({
      reviewType: "google",
    });
    initFacesData({
      reviewType: "faces",
    });
  }, []);

  useEffect(() => {
    if (faces?.data?.getReviewList?.data?.reviews?.length > 2) {
      setIsInfinite(true);
    } else {
      setIsInfinite(false);
    }

    if (google?.data?.getReviewList?.data?.reviews?.length > 2) {
      setIsInfinite1(true);
    } else {
      setIsInfinite1(false);
    }
  }, [
    faces?.data?.getReviewList?.data?.reviews,
    google?.data?.getReviewList?.data?.reviews,
  ]);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      offset: 200,
      once: true, // Set once to true to trigger animations only once
    });
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
      <div className={className} onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M0.294956 1.41L4.87496 6L0.294956 10.59L1.70496 12L7.70496 6L1.70496 -2.62268e-07L0.294956 1.41Z"
            fill="#373C35"
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        // style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M7.88498 1.41L3.30498 6L7.88498 10.59L6.47498 12L0.474976 6L6.47498 -2.62268e-07L7.88498 1.41Z"
            fill="#373C35"
          />
        </svg>
      </div>
    );
  }

  var setting = {
    infinite: true,
    arrows: true,
    autoplaySpeed: 1900,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,

          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if(pageURl) {
      let selectedPageData = TreatmentPages?.Data?.find((item) => item.pageURl == pageURl);
      setPageData(selectedPageData);
    }
  }, [pageURl]);

  console.log("pageData dynamic", pageData);

  if(!pageData) {
    return null;
  }


  return (
    <>
      <div className="main-Treatment-page">
        <Header />

        <section className="bannerSec">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="bannerContent">
                  <h1 data-aos="fade-up" data-aos-duration="700">
                    {pageData?.bannerTitle}
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="750">
                  {pageData?.bannerDesc}
                  </p>

                  <div
                    className="bannerBtnWrapper"
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                 
                    <button className="greenBtn" onClick={()=> {navigate(getTreatmentDetailsRoute(config.MODE_KEY === 'live' ? pageData?.livetreatment_slug : pageData?.treatment_slug));
                      }}>
                      Book Now
                      <span className="iconBtn">
                        <FaArrowRightLong />
                      </span>
                    </button>
                    <button
                      className="greenBtn wpBtn"
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send/?phone=%2B447398592729&text=Hi&type=phone_number&app_absent=0",
                          "_blank"
                        );
                      }}
                    >
                      <span className="iconBtn">
                        <FaWhatsapp />
                      </span>
                      Learn more
                    </button>
                  </div>

                  <div
                    className="reviewWrapper"
                    data-aos="fade-up"
                    data-aos-duration="850"
                  >
                    <ul>
                      <li>
                        <span className="countTitle">3.5K +</span>
                        <span className="countDesc">Consent forms</span>
                      </li>
                      <li>
                        <span className="countTitle">2.4K +</span>
                        <span className="countDesc">Positive reviews</span>
                      </li>
                      <li>
                        <span className="countTitle">15Y +</span>
                        <span className="countDesc">Market experience</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div
                  className="bannerImg"
                  data-aos="fade-left"
                  data-aos-duration="850"
                >
                  <img src={require('../../assets/images/treatment/innerpage/' + pageData?.bannerImg)} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="kanvasMedicalSec sameSpace" id="kanvasMedicalSec" style={{overflow:"hidden"}}>
          <div className="container">
            <div className="sectionTitle">
              <h5
                data-aos="fade-up"
                data-aos-anchor="#kanvasMedicalSec"
                data-aos-offset="700"
                data-aos-duration="700"
              >
                  {pageData?.medicalTitle}
              </h5>
              <p
                data-aos="fade-up"
                data-aos-anchor="#kanvasMedicalSec"
                data-aos-offset="700"
                data-aos-duration="700"
              >
                 {pageData?.medicalDesc}
              </p>
            </div>
            <div className="row">
              {pageData?.getTreatmentGroupData?.map((item, index) => {
                console.log(item, 'item')
                return (
                  <div
                    className="col-sm-12 col-md-6 col-lg-3 mb-3"
                    key={index}
                    data-aos="fade-up"
                    data-aos-anchor="#kanvasMedicalSec"
                    data-aos-offset={600 + index * 100}
                    data-aos-duration={600 + index * 100}
                    onClick={()=> {
                      navigate(gettreatmentlandingRoute(item?.treatment_slug))}}
                  >
                    <div className="groupBox">
                      <div className="groupBoxWrapper">
                          <img src={traPlaceholder} alt="" />
                        <div className="groupimgBox">
                          <img src={require('../../assets/images/treatment/innerpage/' + item?.treatment_image)} alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="groupcontentBox">
                        <h5>{item?.treatment_name}</h5>
                        <p>{item?.treatment_price}</p>
                        <button className="greenBtn">View More</button>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="btnWrapper" 
                    data-aos="fade-up"
                    data-aos-anchor="#kanvasMedicalSec"
                    data-aos-offset={800}
                    data-aos-duration={800} onClick={() =>{navigate(gettreatmentListingRoute())}}>
                <button className="greenBtn">View All</button>
              </div>
            </div>
          </div>
        </section>

        <section className="reviewSec sameSpace bg-black">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2">
                <div className="reviewBoxContent mb-3">
                  <img src={faces_logo} alt="" className="logoReview" />
                  <ul>
                    {[1, 2, 3, 4, 5].map((i, index) => (
                      <li>
                        <img
                          src={
                            index <
                            faces?.data?.getReviewList?.data?.average_rating
                              ? star
                              : stargrey
                          }
                          alt=""
                        />
                      </li>
                    ))}
                  </ul>
                  <p>
                    {faces?.data?.getReviewList?.data?.total_reviews} Reviews
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
                <div className="reviewSliderWrappper">
                  <Slider {...setting}>
                    {faces?.data?.getReviewList?.data?.reviews.map(
                      (item, index) => {
                        return (
                          <div key={index}>
                            <div className="reviewSliderItemBox">
                              <div className="sliderImgWrapper">
                                <div className="imgBox">
                                  {item?.userImage == "" ? (
                                    <Avatar
                                      name={item?.userName}
                                      border="0"
                                      round
                                      size="50"
                                      color="#EEEFF3"
                                      fgColor="#000"
                                      textSizeRatio={2}
                                      className="avtar-image"
                                    />
                                  ) : (
                                    <img src={item?.userImage} alt="" />
                                  )}
                                </div>
                                <div className="imgContent">
                                  <div className="imgleftPart">
                                    <h5>{item?.userName}</h5>
                                    <p>{item?.reviewDate}</p>
                                  </div>
                                  <div className="imgrightPart">
                                    <img src={faces_icon} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="imgReviewContent">
                                <ul>
                                  {[1, 2, 3, 4, 5].map((i, index) => (
                                    <li>
                                      <img
                                        src={
                                          index < item?.userRating
                                            ? star
                                            : stargrey
                                        }
                                        alt=""
                                      />
                                    </li>
                                  ))}
                                </ul>
                                <p>{item?.userReview.slice(0, 200)}</p>

                                <span className="viewMoreBtn">
                                  {" "}
                                  {item?.userReview.length >= 200 && (
                                    <span
                                      onClick={(e) => {
                                        openreviewModel(e, item);
                                      }}
                                      className="viewMore"
                                    >
                                      View More
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2 mb-3">
                <div className="reviewBoxContent mb-3">
                  <img src={googleIconLogo} alt="" className="logoReview" />
                  <ul>
                    {[1, 2, 3, 4, 5].map((i, index) => (
                      <li>
                        <img
                          src={
                            index <
                            google?.data?.getReviewList?.data?.average_rating
                              ? star
                              : stargrey
                          }
                          alt=""
                        />
                      </li>
                    ))}
                  </ul>
                  <p>
                    {google?.data?.getReviewList?.data?.total_reviews} Reviews
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
                <div className="reviewSliderWrappper">
                  <Slider {...setting}>
                    {google?.data?.getReviewList?.data?.reviews.map(
                      (item, index) => {
                        // {googledata.map((item, index) => {
                        return (
                          <div>
                            <div className="reviewSliderItemBox">
                              <div className="sliderImgWrapper">
                                <div className="imgBox">
                                  {/* <img src={item?.reviewrImg} alt="" /> */}
                                  {item?.userImage == "" ? (
                                    <Avatar
                                      name={item?.userName}
                                      border="0"
                                      round
                                      size="50"
                                      color="#EEEFF3"
                                      fgColor="#000"
                                      textSizeRatio={2}
                                      className="avtar-image"
                                    />
                                  ) : (
                                    <img src={item?.userImage} alt="" />
                                  )}
                                </div>
                                <div className="imgContent">
                                  <div className="imgleftPart">
                                    <h5>{item?.userName}</h5>
                                    <p>{item?.reviewDate}</p>
                                  </div>
                                  <div className="imgrightPart">
                                    <img src={googleIcon} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="imgReviewContent">
                                <ul>
                                  {[1, 2, 3, 4, 5].map((i, index) => (
                                    <li>
                                      <img
                                        src={
                                          index < item?.userRating
                                            ? star
                                            : stargrey
                                        }
                                        alt=""
                                      />
                                    </li>
                                  ))}
                                </ul>
                                <p>{item?.userReview.slice(0, 200)}</p>

                                <span className="viewMoreBtn">
                                  {" "}
                                  {item?.userReview.length >= 200 && (
                                    <span
                                      onClick={(e) => {
                                        openreviewModel(e, item);
                                      }}
                                      className="viewMore"
                                    >
                                      View More
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="instagram-FeedSec sameSpace" id="instagram-FeedSec">
          <div className="container">
            <div className="InstaFeedsWrapper">
              <InstaFeeds token={'IGQWRNZAWZA0M24wamFyR0lvbGxfdXlDa28yNlMtUWYwVWFLYW5WaXpyckF2OGZAqWjJ0c21tcFJaYk1JdzVMbHpybXdRU2JtelQ1Vk9mU2ZADOUNjOWlmSklwYi1iV3BtZA1ZAUQ1ZAMYlBNank1U2Rqd0wycmFmWlg1eDQZD'} limit={12}/>
          </div>

            <div className="bannerBtnWrapper mb-0">
              <button
                className="greenBtn"
                data-aos="fade-up"
                data-aos-anchor="#instagram-FeedSec"
                data-aos-offset="800"
                data-aos-duration="800"
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/kanvasmedicalgroup/",
                    "_blank"
                  );
                }}
              >
                Follow us on instagram
                <span className="iconBtn">
                  <FaArrowRightLong />
                </span>
              </button>
            </div>
          </div>
        </section>     */}

        <section className="LipSec sameSpace" id="Lip">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
              <div
                  className="LipImgWrapper"
                  data-aos="fade-right"
                  data-aos-anchor="#Lip"
                  data-aos-offset="800"
                  data-aos-duration="800"
                >
                <div className="LipImgBox">
                  <img src={require('../../assets/images/treatment/innerpage/' + pageData?.aboutImg)} alt="" className="img-fluid" />
                </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 mt-3 mt-md-0">
                <div
                  className="LipContentBox"
                  data-aos="fade-left"
                  data-aos-anchor="#Lip"
                  data-aos-offset="850"
                  data-aos-duration="850"
                >
                  <h5>  {pageData?.aboutTitle} </h5>
                  <p>
                  {pageData?.aboutDesc}
                  </p>
                  <p>
                  {pageData?.aboutDescMore}
                  </p>


                  <div className="bannerBtnWrapper">
                    <button className="greenBtn" onClick={()=> {navigate(getTreatmentDetailsRoute(config.MODE_KEY === 'live' ? pageData?.livetreatment_slug : pageData?.treatment_slug));
                      }}>
                      Book Now
                      <span className="iconBtn">
                        <FaArrowRightLong />
                      </span>
                    </button>
                    <button
                      className="greenBtn wpBtn"
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send/?phone=%2B447398592729&text=Hi&type=phone_number&app_absent=0",
                          "_blank"
                        );
                      }}
                    >
                      <span className="iconBtn">
                        <FaWhatsapp />
                      </span>
                      Learn more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="kanvasMedicalSec TeamSec">
          <HappyClients />
        </section>

        <section
          className="BglipsSec"
          id="Bglipsid"
          // style={{ backgroundImage: `url(${bannerLips})` }}
          style={{ backgroundImage: `url(${require('../../assets/images/treatment/innerpage/' + pageData?.bookanAppoimentImg)})` }}
        >
          <div className="container">
            <div className="BglipsContent">
              <h5
                data-aos="fade-up"
                data-aos-anchor="#Bglipsid"
                data-aos-offset="800"
                data-aos-duration="800"
              >
                {pageData?.bannerDesc}
              </h5>

              <div
                className="outLineBtnWrapper"
                data-aos="fade-up"
                data-aos-anchor="#Bglipsid"
                data-aos-offset="900"
                data-aos-duration="900"
              >
                <button className="outLineBtn" onClick={()=> {navigate(getTreatmentDetailsRoute(config.MODE_KEY === 'live' ? pageData?.livetreatment_slug : pageData?.treatment_slug));
                      }}>
                  Book an appointment
                  <span className="outLineBtnIcon">
                    <FaArrowRightLong />
                  </span>
                </button>
                <button
                  className="outLineBtn"
                  onClick={() => {
                    window.open(
                      "https://api.whatsapp.com/send/?phone=%2B447398592729&text=Hi&type=phone_number&app_absent=0",
                      "_blank"
                    );
                  }}
                >
                  WhatsApp
                  <span className="outLineBtnIcon">
                    <FaArrowRightLong />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="faqSec sameSpace" id="faq">
          <div className="container">
            <div className="sectionTitle">
              <h5
                data-aos="fade-up"
                data-aos-anchor="#faq"
                data-aos-offset="900"
                data-aos-duration="900"
              >
                Things you might want to know
              </h5>
            </div>

            <div className="faqWrapper">
              <div class="accordion" id="accordionExample">
                {pageData?.FAQsData?.map((item, index) => {
                  return (
                    <div
                      class="accordion-item"
                      data-aos="fade-up"
                      data-aos-anchor="#faq"
                      data-aos-offset={800 + index * 100}
                      data-aos-duration={800 + index * 100}
                      key={index}
                    >
                      <h2 class="accordion-header" id={index}>
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapse" + index}
                          aria-expanded="false"
                          aria-controls={"#collapse" + index}
                        >
                          {item?.question}
                        </button>
                      </h2>
                      <div
                        id={"collapse" + index}
                        class="accordion-collapse collapse"
                        aria-labelledby={index}
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">{item?.answer}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <Footer />

        <CustomModal
          modalOpen={reviewViewMore}
          setModalOpen={setreviewViewMore}
          type={"review"}
          className="ReviewMoreDataModel"
          modalBody={
            <>
              <div className="reviewModelWrapper">
                <div className="reviewStarWrapper">
                  <ul>
                    {[1, 2, 3, 4, 5].map((i, index) => (
                      <li>
                        <img
                          src={
                            index < reviewDataModel?.userRating
                              ? star
                              : stargrey
                          }
                          alt=""
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="reviewDescWrapper">
                  <p>{reviewDataModel?.userReview}</p>
                </div>
                <div className="reviewLogoWrapper">
                  <div className="reviewImgWrapper">
                    <div
                      className="reviewImg"
                      onClick={() => {
                        window.open(reviewDataModel?.reviewUrl, "blank");
                      }}
                    >
                      {reviewDataModel?.userImage == "" ? (
                        <Avatar
                          name={reviewDataModel?.userName}
                          border="0"
                          round
                          size="50"
                          color="#EEEFF3"
                          fgColor="#000"
                          textSizeRatio={2}
                          className="avtar-image"
                        />
                      ) : (
                        <img src={reviewDataModel?.userImage} alt="" />
                      )}
                    </div>
                    <div className="reviewContentWrapper">
                      <h5
                        onClick={() => {
                          window.open(reviewDataModel?.reviewUrl, "blank");
                        }}
                      >
                        {reviewDataModel?.userName}
                      </h5>
                      <span
                        onClick={() => {
                          window.open(reviewDataModel?.reviewUrl, "blank");
                        }}
                      >
                        {reviewDataModel?.reviewDate}
                      </span>
                    </div>
                  </div>
                  <span className="comLogo">
                    <img
                      src={
                        reviewDataModel?.reviewFrom === "faces"
                          ? faces_icon
                          : googleIcon
                      }
                    />
                  </span>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

export default TreatmentLandingPage;
