import { CLEAR_TREATEMENT_LIST, GET_TREATMENT_LISTING_CONST } from "../../constants"

const initialState = {
    treatmentsList: [],
    totalTreatmentsCount: 0,
    loadedTreatmentsCount: 0,
    treatmentListPage: 0,
}
export const GetTreatmentListReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TREATMENT_LISTING_CONST:
            // let allTreatments = action.payload.data?.categoryWiseTreatments?.map(e => e.treatments)
            let temp = (state?.treatmentsList).concat(action.payload.data?.items)
            let tempTotalCount = action.payload.data.total;
            let oldTreatmentListPage = state.treatmentListPage + 1;
            return {
                ...state,
                treatmentsList: temp,
                totalTreatmentsCount: action.payload.data.total,
                loadedTreatmentsCount: temp.length,
                treatmentListPage: oldTreatmentListPage,
            };

        case CLEAR_TREATEMENT_LIST:
            return {
                ...state,
                treatmentsList: [],
                totalTreatmentsCount: 0,
                loadedTreatmentsCount: 0,
                treatmentListPage: 0,
            }
        default:
            return state
    }
}  