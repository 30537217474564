import React from 'react'
import Modal from 'react-bootstrap/Modal'
import AuthCarousel from './auth-carousel/AuthCarousel'
import './auth-modal.scss'
import { ReactComponent as Close } from '../../assets/images/modal/Close.svg'
import AuthForms, { clearStates } from './auth-froms/AuthForms'



const AuthModal = ({ setOpen, isOpen: open, setTempLoad, tempLoad, paymentCallback }) => {
    return (
        <>
            <div className="auth-modal">
                <Modal
                    show={open}
                    onHide={() => setOpen(false)}
                    fullscreen
                    style={{ zIndex: 99999 }}
                    dialogClassName='modal-100w'

                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <button className='modal-close-button' onClick={() => clearStates()}>
                        <Close />
                    </button>
                    <Modal.Body className='modal-body-div'>
                        <div className='row align-items-center'>
                            <div className="col-md-6 p-0" >
                                <AuthCarousel />
                            </div>
                            <div className="col-md-6">
                                <AuthForms setTempLoad={setTempLoad} tempLoad={tempLoad} setOpen={setOpen} paymentCallback={paymentCallback} />
                            </div>
                        </div>
                    </Modal.Body >
                </Modal >
            </div >
        </>
    )
}

export default AuthModal